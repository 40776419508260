import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from '../entity-data-service/user.service';
import { AuthorizationService } from '../authorization/authorization.service';
import { UserData } from '../models';
import { FormComponent } from '../form/form.component';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { skipWhile, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent extends FormComponent<UserData> {
  user: UserData;
  writeAccess: boolean = false;
  isAdmin: boolean;

  constructor(
    protected override titleService: Title,
    protected formBuilder: UntypedFormBuilder,
    protected userService: UserService,
    protected authService: AuthorizationService
  ) {
    super(formBuilder, titleService);
  }

  ngOnInit(): void {
    super.ngOnInit;

    this.user = this.authService.verifiedAccountData?.mccUser;
    this.initForm();

    // get roles & permissions data by getting user data by Id
    this.userService
      .getUserById(this.user.id)
      .pipe(
        take(1),
        skipWhile((data) => !data)
      )
      .subscribe((record) => {
        this.user = record;
        this.setAccountContent();
      });
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      firstName: new UntypedFormControl(this.user.firstName),
      lastName: new UntypedFormControl(this.user.lastName),
      username: new UntypedFormControl(this.user.username),
      company: new UntypedFormControl(this.user.customerName),
      roles: new UntypedFormControl(),
    });

    // all form fields are readonly
    this.formGroup.disable();
  }

  setAccountContent() {
    const permissions = [];
    const roleNames = [];

    this.user.roles.forEach((r) => {
      roleNames.push(r.name);
      if (r.isActive) permissions.push(...r.permissions);
    });

    this.formGroup.controls.roles.setValue(roleNames.join(', '));

    this.isAdmin = this.user.roles.some((r) => r.isActive && r.isAdmin);
    this.writeAccess = permissions.some((p) => p.writeAccess);
  }

  manageRolesClick() {
  }

  manageUsersClick() {
  }
}
