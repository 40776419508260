import { Injectable, Type } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';
import { DialogSize, Utility } from './constants';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { FormComponent } from './form/form.component';
import { GenericDialogComponent } from './dialogs/generic-dialog/generic-dialog.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

export enum DisplayStatus {
  Show = 1,
  Hide = 0,
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  isHandset$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((result) => result.matches))
      .subscribe((result) => this.isHandset$.next(result));
  }

  openSnackBar(
    message: string,
    action: string = null,
    config: MatSnackBarConfig = { horizontalPosition: 'center', duration: 5000 }
  ) {
    config = config || { duration: 3000 };
    this.snackBar.open(message.trim(), action, config);
  }

  openDeleteDialog(
    name: string = null,
    itemExists: boolean = true
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: DialogSize.sm,
      data: { name: name, itemExists: itemExists },
    });

    return dialogRef.afterClosed();
  }

  openFormDialog<I, O = I>(
    formComponent: Type<FormComponent<I, O>>,
    heading: string = null,
    confirmText: string = null,
    cancelText: string = null,
    record: I = null,
    mainComponentData: any = {},
    width: DialogSize = DialogSize.lg,
    panelClass: string = null,
    disableClose: boolean = false,
    closeAllOthers: boolean = true,
    closeOnEscapeKey: boolean = true,
    position: DialogPosition = null,
    closeOnSubmit: boolean = true,
    isDraggable: boolean = true,
    closeOnNavigateBack: boolean = true
  ): Observable<any> {
    if (closeAllOthers) this.dialog.closeAll();

    const isHandset = this.isHandset$['_value'];
    if (closeOnNavigateBack && isHandset) {
      if (!history.state?.modalDepth) {
        history.pushState({ modalDepth: 1 }, null, location.href);
      } else {
        history.replaceState(
          { modalDepth: history.state.modalDepth + 1 },
          null,
          location.href
        );
      }
    }

    const dialogRef = this.dialog.open<
      GenericDialogComponent<FormComponent<I, O>, I>
    >(GenericDialogComponent, {
      width: width,
      panelClass: panelClass,
      disableClose: disableClose,
      data: {
        record: record,
      },
      position,
    });

    // Set escape key to trigger closing the dialog
    if (disableClose && closeOnEscapeKey) {
      dialogRef.keydownEvents().subscribe((event) => {
        if (event.key === 'Escape') dialogRef.close();
      });
    }

    if (Utility.HasValue(mainComponentData)) {
      mainComponentData.cancelText = mainComponentData.cancelText || cancelText;
      mainComponentData.confirmText =
        mainComponentData.confirmText || confirmText;
      dialogRef.componentInstance.mainComponentData = mainComponentData;
    }

    dialogRef.componentInstance.isFormComponent = true;
    dialogRef.componentInstance.mainComponent = formComponent;
    dialogRef.componentInstance.heading = heading;
    dialogRef.componentInstance.cancelText = cancelText;
    dialogRef.componentInstance.showActions = false;
    dialogRef.componentInstance.closeOnSubmit = closeOnSubmit;
    dialogRef.componentInstance.isDraggable = isDraggable;

    if (closeOnNavigateBack && isHandset) {
      dialogRef.componentInstance.data.modalDepth = history.state?.modalDepth;
      dialogRef
        .afterClosed()
        .subscribe(
          () =>
            dialogRef.componentInstance.data?.modalDepth ===
              history.state?.modalDepth && history.back()
        );
    }

    return closeOnSubmit
      ? dialogRef.afterClosed()
      : combineLatest([dialogRef.componentInstance.record$, of(dialogRef)]);
  }
}
