<div class="division-buttons-container">
  <div *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div *ngIf="!!aggregateDivision" class="division-button-container aggregate-container">
    <div class="division-info-container">
      <div class="division-title">Aggregate</div>
      <button mat-flat-button color="primary" (click)="openOrderForm(orderTypes.Aggregate)">
        Place Order
      </button>
    </div>
  </div>
  <div *ngIf="!!concreteDivision" class="division-button-container concrete-container">
    <div class="division-info-container">
      <div class="division-title">Concrete</div>
      <button mat-flat-button color="primary" (click)="openOrderForm(orderTypes.Concrete)">
        Place Order
      </button>
    </div>
  </div>
</div>