<div>
  <mat-toolbar class="sticky-mat-toolbar">
    <div class="toolbar-header-row">
      <div id="heading">
        <h1>{{ heading }}</h1>
      </div>
      <mat-form-field class="flex-auto" >
        <mat-label>Search Tickets</mat-label>
        <input matInput (focus)="$event.target.select()" (keyup)="applyFilter($event)" #input />
        <mat-icon matSuffix>filter_list</mat-icon>
      </mat-form-field>
      <mat-form-field class="flex-wide">
        <mat-label>Status</mat-label>
        <mat-select [value]="selectedStatus.id" (selectionChange)="onStatusChange($event)">
          <mat-option *ngFor="let status of statusOptions" [value]="status.id">
            {{status.statusName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="date-input">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="pickerForDay" [formControl]="selectedDateCtrl"
          (dateChange)="onDateChange($event)" (focus)="$event.target.select()" />
        <mat-datepicker-toggle matIconSuffix [for]="pickerForDay" [disableRipple]="true"></mat-datepicker-toggle>
        <mat-datepicker #pickerForDay [touchUi]="true"></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-toolbar>
  <div>
    <div class="table-scroll">
      <table mat-table fixedLayout="true" matSort [dataSource]="dataSource" [trackBy]="trackByTicket">
        <!-- Action Column Definition -->
        <ng-container matColumnDef="actions" sticky>
          <th mat-header-cell class="th-ticket-button" *matHeaderCellDef cdkDrag>
            <div class="cdk-visually-hidden">Actions</div>
          </th>
          <td mat-cell *matCellDef="let record">
            <div class="ticket-button-wrapper">
              <button mat-flat-button class="ticket-button" (click)="onTicketView(record)">
                {{ record.id }}
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Record Property Definition -->
        <ng-container [matColumnDef]="column" *ngFor="let column of propertyCodeNames; let i = index; trackBy: trackBy">
          <th mat-header-cell [style.width]="propertyWidths[i]" *matHeaderCellDef mat-sort-header
            class="th-property-header">
            <div class="property-cell">
              {{propertyDisplayNames[i]}}
            </div>
          </th>
          <td mat-cell *matCellDef="let record">
            @if (propertyWidths[i] !== 'auto') {
            <div [style.minWidth]="propertyWidths[i]">
              <ng-container *ngTemplateOutlet="propertyCell"></ng-container>
            </div>
            } @else {
            <div class="cell-width">
              <ng-container *ngTemplateOutlet="propertyCell"></ng-container>
            </div>
            }
            <ng-template #propertyCell>
              <div class="property-cell" [matTooltip]="record[column]" matTooltipTouchGestures="off">
                {{record[column]}}
              </div>
            </ng-template>
          </td>
        </ng-container>

        <!-- Table Header -->
        <tr mat-header-row *matHeaderRowDef="columns"></tr>

        <!-- Table Row Data -->
        <tr mat-row *matRowDef="let row; columns: columns">
        </tr>

        <!-- Table Row when DataSource is empty -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="columns?.length">
            <ng-container *ngIf="isLoading; then loadingDataMessage; else noDataMessage"></ng-container>
          </td>

          <ng-template #loadingDataMessage>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </ng-template>

          <!-- No Records Message -->
          <ng-template #noDataMessage>
            <div class="data-message">
              No data matching the filter on <strong>{{ noRecordsDate }}</strong>
            </div>
          </ng-template>
        </tr>
      </table>
    </div>
  </div>
</div>
<mat-paginator *ngIf="showPaginator" [pagSizeOptions]="pageSizeOptions"></mat-paginator>