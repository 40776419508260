import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormComponent } from '../../form/form.component';
import {
  EntityData,
  ItemFormData,
  ZendiProductUseData,
  ZendiProductData,
} from '../../models';
import { MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MessageService } from '../../message.service';
import { BehaviorSubject } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemFormComponent extends FormComponent<ItemFormData> {
  @Input() label: string = 'Products';
  @Input() data: ItemFormData;
  @Input() quantity: number;
  @Input() showSlump: boolean = true;
  @Input() required: boolean = false;
  @Input() product: ZendiProductData;
  @Input() products: ZendiProductData[] = [];
  @Input() productUses: ZendiProductUseData[] = [];
  @Input() formDialogRef: MatDialogRef<FormComponent<EntityData>>;

  @Output() onQuantityChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('autoProduct') autoProduct: MatAutocomplete;

  isHandset: boolean = false;
  unitOfMeasure: string;
  filteredProductUses$: BehaviorSubject<ZendiProductUseData[]> =
    new BehaviorSubject<ZendiProductUseData[]>([]);
  filteredProducts$: BehaviorSubject<ZendiProductData[]> = new BehaviorSubject<
    ZendiProductData[]
  >([]);
  selectedProduct$: BehaviorSubject<ZendiProductData> =
    new BehaviorSubject<ZendiProductData>(null);

  //#region Form Controls
  private productCtrl: UntypedFormControl;
  private quantityCtrl: UntypedFormControl;
  private slumpCtrl: UntypedFormControl;
  private productUseCtrl: UntypedFormControl;
  private unitCtrl: UntypedFormControl;
  //#endregion

  constructor(
    protected titleService: Title,
    protected formBuilder: UntypedFormBuilder,
    protected messageService: MessageService
  ) {
    super(formBuilder, titleService);
  }

  displayProductVal = (option: ZendiProductData) =>
    option.id ? `${option.code} - ${option.description}` : '';

  ngOnInit(): void {
    super.ngOnInit();

    this.isHandset = this.messageService.isHandset$['_value'];
    this.initForm();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected initForm(): void {
    this.initFormControls();

    this.formGroup = this.formBuilder.group({
      product: this.productCtrl,
      quantity: this.quantityCtrl,
      unitOfMeasure: this.unitCtrl,
      slump: this.slumpCtrl,
      productUse: this.productUseCtrl,
    });

    if (this.readonly) {
      this.formGroup.disable();
    }
  }

  initFormControls() {
    const numberValidators = [
      this.required ? Validators.required : Validators.nullValidator,
    ];

    this.productCtrl = new UntypedFormControl(
      this.data?.product || '',
      numberValidators
    );
    this.productUseCtrl = new UntypedFormControl(this.data?.productUse || '');
    this.unitCtrl = new UntypedFormControl(
      this.data?.product?.unitOfMeasureId || this.data?.unitOfMeasureId || ''
    );
    this.quantityCtrl = new UntypedFormControl(
      this.data?.quantity,
      Validators.required
    );
    this.slumpCtrl = new UntypedFormControl(
      this.data?.slump,
      numberValidators
    );
  }

  setProduct(value: ZendiProductData, skipValCheck: boolean = false) {
    if (!skipValCheck && value?.id === this.selectedProduct$.value?.id) return;

    this.productCtrl.setValue(value);
    this.selectedProduct$.next(value);
    this.onInputInteraction(null);
  }

  private filterProducts = (filterVal: string) => {
    return this.filterAutoCompleteOptions(
      filterVal,
      this.products,
      ['code', 'description'],
      'code',
      false,
      this.selectedProduct$.value,
      (option) => option.id === this.selectedProduct$.value?.id
    );
  };

  private filterProductUses = (filterVal: string) => {
    return this.filterAutoCompleteOptions(
      filterVal,
      this.productUses,
      ['name'],
      'name',
      true
    );
  };

  clearInvalidProduct() {
    if (!this.autoProduct.isOpen && !this.productCtrl.value?.id)
      this.setProduct(null, true);
  }

  onProductClosed() {
    const selectedOpt = this.autoProduct.options.find((o) => o.selected);
    const activeOpt = this.autoProduct.options.find((o) => o.active);

    if (typeof this.productCtrl.value === 'string' && !activeOpt) {
      if (this.productCtrl.value === '') {
        this.setProduct(null, true);
      } else if (this.autoProduct.options.first?.value) {
        this.setProduct(this.autoProduct.options.first.value, true);
      }
    } else {
      if (
        selectedOpt &&
        selectedOpt.value?.id !== this.selectedProduct$.value?.id
      ) {
        this.setProduct(selectedOpt.value, true);
      } else if (activeOpt) {
        this.setProduct(activeOpt.value, true);
      }
    }
  }

  onProductUseClosed() {
    if (typeof this.productUseCtrl.value === 'string') {
      const record = this.filteredProductUses$.value?.find(
        (o) => o.id === this.productUseCtrl.value
      );

      this.productUseCtrl.setValue(record || this.productUseCtrl.value);
    }

    this.onInputInteraction(event);
  }

  trimProductUse(event: Event) {
    this.formGroup.controls.productUse.setValue(
      this.productUseCtrl.value.trim()
    );
    this.onInputInteraction(event);
  }

  onBlurQuantity(changes?: any) {
    // If item is Primary Product & changes occurred, emit change to Quantity
    if (this.data.isPrimaryProduct && changes)
      this.onQuantityChange.emit(this.formGroup?.controls?.quantity.value);
  }

  protected onInputInteraction(event: any): void {}
}
