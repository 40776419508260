import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormComponent } from '../form/form.component';
import { AddressFormData, EntityData, ZendiTaxRateData } from '../models';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { MessageService } from '../message.service';
import { States } from '../constants';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject, map, startWith, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent extends FormComponent<AddressFormData> {
  @Input() formGroup: UntypedFormGroup;
  @Input() formDialogRef: MatDialogRef<FormComponent<EntityData>>;
  @Input() required: boolean = false;
  @Input() showTaxRate: boolean = true;
  @Input() defaultState: string = '--';
  @Input() matFormFieldInputClass: string = '';
  @Input() taxRates: ZendiTaxRateData[] = [];
  @Input() hideLatLong: boolean = false;

  @Output()
  onChanges: EventEmitter<AddressFormData> =
    new EventEmitter<AddressFormData>();

  @ViewChild('autoTaxRate') autoTaxRate: MatAutocomplete;

  latLongClass: string = 'flex-narrow';
  states = States;
  filteredTaxRateOptions: BehaviorSubject<ZendiTaxRateData[]> =
    new BehaviorSubject<ZendiTaxRateData[]>([]);

  displayTaxRateValue = (option: ZendiTaxRateData) =>
    !option?.id ? '' : `${option?.code} - ${option?.description}`;

  constructor(
    protected titleService: Title,
    protected formBuilder: UntypedFormBuilder,
    protected messageService: MessageService
  ) {
    super(formBuilder, titleService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const isHandset = this.messageService.isHandset$['_value'];
    if (isHandset) this.latLongClass = 'flex-narrow-mobile';

    this.formGroup =
      this.formGroup ||
      AddressComponent.createAddressFormGroup(
        this.initRecord,
        this.required,
        this.defaultState
      );

    if (this.readonly) this.formGroup.disable();
    this.subscribeToFormControlChanges();
    this.filteredTaxRateOptions.next(this.filterTaxRateOptions(''));
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.formDialogRef) {
      // setup scroll locking triggers for autocomplete elements
      const formDialogEl = document.getElementById(this.formDialogRef.id);
      const disableScroll = () => (formDialogEl.style.overflow = 'hidden');
      const enableScroll = () => (formDialogEl.style.overflow = 'auto');
      const applyScrollHandlers = (
        subscription: Subscription,
        autocomplete: MatAutocomplete
      ) => {
        if (autocomplete) {
          autocomplete.opened
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(disableScroll);
          autocomplete.closed
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(enableScroll);
          subscription.unsubscribe();
        }
      };
    }
  }

  ngOnChanges(changes: any) {
    super.ngOnChanges(changes);

    if (changes?.initRecord) {
      this.checkAndApplyValueChange(changes.initRecord, 'address_Name');
      this.checkAndApplyValueChange(changes.initRecord, 'address_Street');
      this.checkAndApplyValueChange(changes.initRecord, 'address_City');
      this.checkAndApplyValueChange(changes.initRecord, 'address_State');
      this.checkAndApplyValueChange(changes.initRecord, 'address_Zip');
      this.checkAndApplyValueChange(changes.initRecord, 'address_Latitude');
      this.checkAndApplyValueChange(changes.initRecord, 'address_Longitude');
      this.checkAndApplyValueChange(changes.initRecord, 'taxRate');
    }

    // Auto-Populate default address state if form control value is empty
    if (
      changes?.defaultState &&
      !this.formGroup?.controls.address_State?.value
    ) {
      this.formGroup?.controls.address_State?.setValue(this.defaultState);
    }
  }

  static createAddressFormGroup(
    initRecord: AddressFormData,
    required: boolean,
    defaultState: string
  ): UntypedFormGroup {
    // If the Address Lat/Lng are set to the default of 0, set them to the default specified by the defaultCenter variable
    const lat = parseFloat(initRecord?.address_Latitude);
    const lng = parseFloat(initRecord?.address_Longitude);

    if (isNaN(lat) || lat === 0) initRecord.address_Latitude = null;
    if (isNaN(lng) || lng === 0) initRecord.address_Longitude = null;

    const formGroup = new UntypedFormGroup({
      address_Street: new UntypedFormControl(
        FormComponent.getInitValue<AddressFormData>(
          initRecord,
          'address_Street'
        ) || '--',
        required ? Validators.required : Validators.nullValidator
      ),
      address_City: new UntypedFormControl(
        FormComponent.getInitValue<AddressFormData>(
          initRecord,
          'address_City'
        ) || '--',
        required ? Validators.required : Validators.nullValidator
      ),
      address_State: new UntypedFormControl(
        FormComponent.getInitValue<AddressFormData>(
          initRecord,
          'address_State'
        ) ?? defaultState,
        required ? Validators.required : Validators.nullValidator
      ),
      address_Zip: new UntypedFormControl(
        FormComponent.getInitValue<AddressFormData>(
          initRecord,
          'address_Zip'
        ) || '--',
        [Validators.minLength(5), Validators.maxLength(10)]
      ),
      address_Latitude: new UntypedFormControl(
        FormComponent.getInitValue<AddressFormData>(
          initRecord,
          'address_Latitude'
        ) || '--'
      ),
      address_Longitude: new UntypedFormControl(
        FormComponent.getInitValue<AddressFormData>(
          initRecord,
          'address_Longitude'
        ) || '--'
      ),
      address_County: new UntypedFormControl(
        FormComponent.getInitValue<AddressFormData>(
          initRecord,
          'address_County'
        ) || '--'
      ),
      taxRate: new UntypedFormControl(
        FormComponent.getInitValue<AddressFormData>(initRecord, 'taxRate') ||
          '--'
      ),
    });
    return formGroup;
  }

  protected checkAndApplyValueChange(initRecord: any, property: string) {
    this.formGroup?.get(property)?.setValue(initRecord?.currentValue[property]);
  }

  protected onInputInteraction(event: Event = null): void {
    this.onChanges.emit(this.formGroup.getRawValue());
  }

  onTaxRateOptionsClosed() {
    if (!this.formGroup.controls.taxRate.value?.id) {
      this.formGroup.controls.taxRate.setValue(null);
    }
    this.onInputInteraction();
  }

  protected filterTaxRateOptions = (filterVal: string): ZendiTaxRateData[] => {
    return this.filterAutoCompleteOptions(
      filterVal,
      this.taxRates,
      ['code', 'description'],
      'code',
      true,
      this.formGroup?.controls?.taxRate?.value,
      (option) => option.id == this.formGroup?.controls?.taxRate?.value?.id
    );
  };

  protected subscribeToFormControlChanges() {
    this.formGroup.controls.taxRate.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        startWith(''),
        map((value: string) =>
          this.filterTaxRateOptions(typeof value == 'string' ? value : '')
        )
      )
      .subscribe(this.filteredTaxRateOptions);
  }
}
