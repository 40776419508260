import {
  APP_INITIALIZER,
  EnvironmentProviders,
  NgModule,
  Provider,
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseComponent } from './base/base.component';
import { NavbarComponent } from './navbar/navbar.component';
import {
  AuthClientConfig,
  AuthHttpInterceptor,
  authHttpInterceptorFn,
  AuthModule,
} from '@auth0/auth0-angular';
import { withInterceptors } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { environment } from '../environments/environment';
import { HttpModule } from './http.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogModule,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TicketDashboardComponent } from './operations/ticket-dashboard/ticket-dashboard.component';
import { RecordListComponent } from './record-list/record-list.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  MatNativeDateModule,
  MatOptionModule,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccountComponent } from './account/account.component';
import { FormComponent } from './form/form.component';
import { PlaceOrderComponent } from './operations/place-order/place-order.component';
import { GenericDialogComponent } from './dialogs/generic-dialog/generic-dialog.component';
import { TicketComponent } from './operations/ticket/ticket.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddressComponent } from './address/address.component';
import { ItemFormComponent } from './operations/item/item.component';
import { OrderDashboardComponent } from './operations/order-dashboard/order-dashboard.component';

export function appInit(auth0Config: AuthClientConfig) {
  if (environment.enableAuth)
    return () =>
      auth0Config.set({
        clientId: environment.clientId,
        domain: environment.authority,
        authorizationParams: {
          redirect_uri: environment.redirectUrl,
          audience: environment.authorityId,
        },
        httpInterceptor: {
          /* Developer Note: see the following link for configuring the httpInterceptor, if a public route is needed in the future: */
          /* https://github.com/auth0/auth0-angular/blob/main/EXAMPLES.md#configure-authhttpinterceptor-to-attach-access-tokens */
          /* Otherwise, this interceptor will ensure that all calls to the designated API are protected by Auth0. */
          allowedList: [
            {
              uri: `${environment.apiUrl}/*`,
              tokenOptions: {
                authorizationParams: {
                  audience: environment.authorityId,
                },
              },
            },
          ],
        },
      });
  else return null;
}

const getAuthProviders = (): Provider | EnvironmentProviders => {
  const providers = [
    AuthHttpInterceptor,
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
  ];
  return [...providers];
};

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    FormComponent,
    RecordListComponent,
    GenericDialogComponent,
    ConfirmDeleteComponent,
    AddressComponent,
    ItemFormComponent,
    TicketDashboardComponent,
    TicketComponent,
    AccountComponent,
    OrderDashboardComponent,
    PlaceOrderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DragDropModule,
    MatButtonModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogContent,
    MatDialogTitle,
    MatDialogClose,
    MatDialogActions,
    MatDialogModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatPaginator,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    AuthModule.forRoot(),
    HttpModule.forRoot({ environment }),
  ],
  providers: [
    Title,
    provideNativeDateAdapter(),
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AuthClientConfig],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic', appearance: 'fill' },
    },
    getAuthProviders(),
    CommonModule,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
