import { Inject, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { ENV_CONFIG, EnvironmentConfig } from '../environment.config';
import {
  ZendiLocationData,
  ZendiQuoteData,
  ZendiTaxRateData,
  ZendiTicketData,
  ZendiUnitOfMeasureData,
  ZendiProductData,
  ZendiSaleTypeSectionData,
  DeliveredTotalData,
  Division,
  TicketDashboardData,
  ZendiTicketStateData,
  TicketedTotalData,
  DateFormat,
  OrderDashboardOrderData,
} from '../models';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class OperationService extends ApiService {
  //Subjects: anything that subscribes will get the latest version of the subject
  private zendiLocationsSbj: BehaviorSubject<ZendiLocationData[]> =
    new BehaviorSubject<ZendiLocationData[]>([]);
  private zendiProductsSbj: BehaviorSubject<ZendiProductData[]> =
    new BehaviorSubject<ZendiProductData[]>([]);
  private zendiQuotesSbj: BehaviorSubject<ZendiQuoteData[]> =
    new BehaviorSubject<ZendiQuoteData[]>([]);
  private zendiUnitOfMeasuresSbj: BehaviorSubject<ZendiUnitOfMeasureData[]> =
    new BehaviorSubject<ZendiUnitOfMeasureData[]>([]);
  private zendiSaleTypeSectionsSbj: BehaviorSubject<
    ZendiSaleTypeSectionData[]
  > = new BehaviorSubject<ZendiSaleTypeSectionData[]>([]);
  private zendiTaxRatesSbj: BehaviorSubject<ZendiTaxRateData[]> =
    new BehaviorSubject<ZendiTaxRateData[]>([]);
  private zendiTicketStatesSubject: BehaviorSubject<ZendiTicketStateData[]> =
    new BehaviorSubject<ZendiTicketStateData[]>([]);
  private dashboardTicketsSbj: BehaviorSubject<TicketDashboardData[]> =
    new BehaviorSubject<TicketDashboardData[]>([]);
  private dashboardOrdersSbj: BehaviorSubject<OrderDashboardOrderData[]> =
    new BehaviorSubject<OrderDashboardOrderData[]>([]);
  private deliveredTotalSbj: BehaviorSubject<DeliveredTotalData> =
    new BehaviorSubject<DeliveredTotalData>(null);
  private ticketedTotalSbj: BehaviorSubject<TicketedTotalData> =
    new BehaviorSubject<TicketedTotalData>(null);
  private divisionsSubject: BehaviorSubject<Division[]> = new BehaviorSubject<
    Division[]
  >([]);

  //Subject Observables: allows components to subscribe to changes of the subject.
  get zendiLocations(): Observable<ZendiLocationData[]> {
    return this.zendiLocationsSbj;
  }
  get zendiProducts(): Observable<ZendiProductData[]> {
    return this.zendiProductsSbj;
  }
  get zendiQuotes(): Observable<ZendiQuoteData[]> {
    return this.zendiQuotesSbj;
  }
  get zendiUnitOfMeasures(): Observable<ZendiUnitOfMeasureData[]> {
    return this.zendiUnitOfMeasuresSbj;
  }
  get zendiSaleTypeSections(): Observable<ZendiSaleTypeSectionData[]> {
    return this.zendiSaleTypeSectionsSbj;
  }
  get zendiTaxRates(): Observable<ZendiTaxRateData[]> {
    return this.zendiTaxRatesSbj;
  }
  get zendiTicketStates(): Observable<ZendiTicketStateData[]> {
    return this.zendiTicketStatesSubject;
  }
  get dashboardTickets(): Observable<TicketDashboardData[]> {
    return this.dashboardTicketsSbj;
  }
  get dashboardOrders(): Observable<OrderDashboardOrderData[]> {
    return this.dashboardOrdersSbj;
  }
  get deliveredTotal(): Observable<DeliveredTotalData> {
    return this.deliveredTotalSbj;
  }
  get ticketedTotal(): Observable<TicketedTotalData> {
    return this.ticketedTotalSbj;
  }
  get divisions(): Observable<Division[]> {
    return this.divisionsSubject;
  }

  private dataUrl: {
    zendiLocation: string;
    zendiProduct: string;
    zendiQuote: string;
    zendiUnitOfMeasure: string;
    zendiSaleTypeSection: string;
    zendiTaxRate: string;
    zendiTicket: string;
    zendiTicketState: string;
    orderDashboard: string;
    division: string;
  };

  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) protected config: EnvironmentConfig
  ) {
    super(http, config);

    this.dataUrl = {
      zendiLocation: `${this.config.environment.apiUrl}/zendiLocation`,
      zendiProduct: `${this.config.environment.apiUrl}/zendiProduct`,
      zendiQuote: `${this.config.environment.apiUrl}/zendiQuote`,
      zendiUnitOfMeasure: `${this.config.environment.apiUrl}/zendiUnitOfMeasure`,
      zendiSaleTypeSection: `${this.config.environment.apiUrl}/zendiSaleTypeSection`,
      zendiTaxRate: `${this.config.environment.apiUrl}/zendiTaxRate`,
      zendiTicket: `${this.config.environment.apiUrl}/zendiTicket`,
      zendiTicketState: `${this.config.environment.apiUrl}/zendiTicketState`,
      orderDashboard: `${this.config.environment.apiUrl}/orderDashboard`,
      division: `${this.config.environment.apiUrl}/division`,
    };
  }

  public getZendiTicket(id: number) {
    return this.getDataRecord<ZendiTicketData>(
      `${this.dataUrl.zendiTicket}/GetZendiTicket?id=${id}`,
      null,
      ZendiTicketData
    );
  }

  public getTicketDashboardTickets(
    customerId: number,
    date: DateTime,
    errorHandler: () => void = function () {}
  ) {
    if (customerId === null || date === null) return;
    const formattedDate = date.toISODate();
    this.getDataRecords<TicketDashboardData>(
      `${this.dataUrl.zendiTicket}/GetTicketDashboardTickets?customerId=${customerId}&ticketDate=${formattedDate}`,
      this.dashboardTicketsSbj,
      TicketDashboardData,
      errorHandler
    );
  }

  public getZendiTicketStates() {
    this.getDataRecords<ZendiTicketStateData>(
      `${this.dataUrl.zendiTicketState}/GetZendiTicketStates`,
      this.zendiTicketStatesSubject,
      ZendiTicketStateData
    );
  }
  // #endregion

  // #region Order Dashboard
  public getOrderDashboardOrders(
    customerId: number,
    date: DateTime,
    errorHandler: () => void = function () {}
  ) {
    if (customerId === null || date === null) return;
    const formattedDate = date.toFormat('yyyy-MM-dd');
    this.getDataRecords<OrderDashboardOrderData>(
      `${this.dataUrl.orderDashboard}/GetOrderDashboardOrders?customerId=${customerId}&orderDate=${formattedDate}`,
      this.dashboardOrdersSbj,
      OrderDashboardOrderData,
      errorHandler
    );
  }

  public getDeliveredTotal(customerId: number, date: DateTime) {
    if (customerId === null || date === null) return;
    const formattedDate = date.toFormat(DateFormat.DateShort);

    this.getDataRecord<DeliveredTotalData>(
      `${this.dataUrl.orderDashboard}/GetDeliveredTotal?customerId=${customerId}&orderDate=${formattedDate}`,
      this.deliveredTotalSbj,
      DeliveredTotalData
    );
  }

  public getTicketedTotal(customerId: number, date: DateTime) {
    if (customerId === null || date === null) return;
    const formattedDate = date.toFormat(DateFormat.DateShort);

    this.getDataRecord<TicketedTotalData>(
      `${this.dataUrl.orderDashboard}/GetTicketedTotal?customerId=${customerId}&orderDate=${formattedDate}`,
      this.ticketedTotalSbj,
      TicketedTotalData
    );
  }
  // #endregion

  // #region Divisions
  public getDivisions() {
    this.getDataRecords<Division>(
      `${this.dataUrl.division}/GetDivisions`,
      this.divisionsSubject,
      Division
    );
  }
  // #endregion

  public getZendiLocations() {
    return this.getDataRecords<ZendiLocationData>(
      `${this.dataUrl.zendiLocation}/GetZendiLocations`,
      this.zendiLocationsSbj,
      ZendiLocationData
    );
  }

  public getZendiProducts() {
    return this.getDataRecords<ZendiProductData>(
      `${this.dataUrl.zendiProduct}/GetZendiProducts`,
      this.zendiProductsSbj,
      ZendiProductData
    );
  }

  public getZendiQuotes(customerId: number) {
    return this.getDataRecords<ZendiQuoteData>(
      `${this.dataUrl.zendiQuote}/GetZendiQuotesByCustomer?customerId=${customerId}`,
      this.zendiQuotesSbj,
      ZendiQuoteData
    );
  }

  public getZendiSaleTypeSections() {
    return this.getDataRecords<ZendiSaleTypeSectionData>(
      `${this.dataUrl.zendiSaleTypeSection}/GetZendiSaleTypeSections`,
      this.zendiSaleTypeSectionsSbj,
      ZendiSaleTypeSectionData
    );
  }

  public getZendiTaxRates() {
    return this.getDataRecords<ZendiTaxRateData>(
      `${this.dataUrl.zendiTaxRate}/GetZendiTaxRates`,
      this.zendiTaxRatesSbj,
      ZendiTaxRateData
    );
  }

  public getZendiUnitOfMeasures() {
    return this.getDataRecords<ZendiUnitOfMeasureData>(
      `${this.dataUrl.zendiUnitOfMeasure}/GetZendiUnitOfMeasures`,
      this.zendiUnitOfMeasuresSbj,
      ZendiUnitOfMeasureData
    );
  }
}
