import { MatTableDataSource } from '@angular/material/table';
import { User } from '@auth0/auth0-angular';
import { Utility } from './constants';
import { DateTime } from 'luxon';

export enum PermissionRoutesEnum {
  Account = 'account',
  //Order Tab
  Order_ViewOrder = 'order/view-order',
  //Operations
  Operations_OrderDashboard = 'operations/order-dashboard',
  Operations_TicketDashboard = 'operations/ticket-dashboard',
  Operations_PlaceOrder = 'operations/place-order',
}

export enum DateFormat {
  TimeWithLongOffset = 'yyyy-MM-dd HH:mm:ss',
  DateShort = 'MM/dd/yyyy',
  Time24Simple = 'HH:mm',
}

// #region Core Data Models
export class EntityData {
  id?: number;
  rowVersion?: Uint8Array;

  static equalRowVersion(a: Uint8Array, b: Uint8Array) {
    return a?.length === b?.length && a.every((val, i) => val === b[i]);
  }
}

export class PropertyName {
  constructor(
    public codeName: string = null,
    public displayName: string = null,
    public width: string = null,
    public applyFilter: boolean = true,
    public isHiddenProperty: boolean = false,
    public filterName: string = null
  ) {}
}

export class RecordActionSet {
  create?: boolean;
  delete?: boolean;
  view?: boolean;
  edit?: boolean;
  select?: boolean;
  selectRow?: boolean;
}

export enum RecordAction {
  Create,
  Delete,
  View,
  Edit,
  Select,
  SelectRow,
}

export class RecordListModel<T> {
  constructor(
    public availableActions: RecordActionSet = {},
    public dataSource: MatTableDataSource<T> = new MatTableDataSource<T>([]),
    public recordProperties: PropertyName[] = [],
    public newButtonText: string = 'New',
    public confirmDelete: boolean = true,
    public heading: string = null,
    public isDraggable: boolean = false,
    public isLoading: boolean,
    public showFilter: boolean = true,
    public showPaginator: boolean = true,
    public showCloseControl: boolean = false,
    public noRecordsMessage: string,
    public pageSizeOptions: number[] = [50, 75, 100, 250],
    public getRecordName = null,
    public trackBy = null,
    public isElevated: boolean = null
  ) {}
}

export class Division extends EntityData {
  name: string;
  saleTypeId: number;
  isActive: boolean;
}
export class ZendiLocationData extends EntityData {
  code: string;
  name: string;
}

export class ZendiProductData extends EntityData {
  code: string;
  description: string;
  unitOfMeasureId: number;
}

export class ZendiQuoteData extends EntityData {
  name: string;
}

export class ZendiUnitOfMeasureData extends EntityData {
  code: string;
  description: string;
}

export class ZendiTaxRateData extends EntityData {
  code: string;
  description: string;
}

export class ZendiProductUseData extends EntityData {
  name: string;
}

export class ZendiTicketStateData extends EntityData {
  code: string;
  name: string;
  ticketWorkflowId: number;
  ticketWorkflowName: string;
  sequence: number;
  isCompletedState: boolean;
  isDeliveredState: boolean;
  durationCalculation?: number;
  durationThreshold?: number;
  dashboardColumnIndex?: number;
  dashboardColumns?: number;

  statusName?: string;
}

export class ZendiSaleTypeSectionData extends EntityData {
  saleTypeId: number;
  name: string;
  showSlumpColumn: boolean;
  sortOrder: number;
}

//#region Zendi Ticket
export class TicketDashboardData extends EntityData {
  dateTime: Date;
  ticketStateDateTime: Date;
  loadStartDateTime?: Date;
  returnTime?: number;
  orderNumber?: number;
  orderId?: number;
  ticketWorkflowId: number;
  status: string;
  saleType: string;
  ticketStateId: number;
  ticketStateSequence: number;
  equipment: string;
  product: string;
  quantity: string;
  deliveryAddressName: string;
  deliveryAddressStreet: string;
  deliveryAddressCity: string;
  deliveryAddressState: string;
  deliveryAddressZip: string;
  deliveryAddressLongitude?: number;
  deliveryAddressLatitude?: number;

  get summary(): string {
    if (Utility.IsNullOrUndefined(this.deliveryAddressCity))
      return 'No Delivery Address';

    return `${this.deliveryAddressName ?? ''} ${
      this.deliveryAddressStreet ?? ''
    }, ${this.deliveryAddressCity ?? ''}`.trim();
  }

  get loadTime(): string {
    if (Utility.IsNullOrUndefined(this.loadStartDateTime)) return '';

    const jsDate = new Date(this.loadStartDateTime);
    if (isNaN(jsDate.getTime())) {
      return 'Invalid';
    }

    const dateTime = DateTime.fromJSDate(jsDate);
    return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  get jobTime(): string {
    const jsDate = new Date(this.dateTime);
    if (isNaN(jsDate.getTime())) {
      return 'Invalid';
    }

    const dateTime = DateTime.fromJSDate(jsDate);
    return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
  }
}

export class ZendiTicketData extends EntityData {
  orderId?: number;
  dateTime: Date;
  loadStartDateTime?: Date;
  atJobTimestamp?: Date;
  loadTimestamp?: Date;
  endJobTimestamp?: Date;
  ticketStateId: number;
  taxRateId: number;
  fromLocationId: number;
  saleTypeId: number;
  saleType: string;

  //customer data
  customerId: number;
  customerJob?: string;
  customerPurchaseOrder: string;
  paymentType: string;
  quoteId?: number;

  // address data
  deliveryAddressName: string;
  deliveryAddressStreet: string;
  deliveryAddressCity: string;
  deliveryAddressState: string;
  deliveryAddressZip: string;
  deliveryAddressLongitude?: number;
  deliveryAddressLatitude?: number;

  // data not from Zendi Ticket table
  ticketItems: ZendiTicketItemData[];
  ticketWorkflowId: number;
  primaryProductQuantity?: number;
}

export class ZendiTicket extends ZendiTicketData {
  orderNumber?: number;
  equipment: string;
  ticketStateName: string;
  saleType: string;
  paymentType: string;

  constructor(data: ZendiTicketData) {
    super();

    if (Utility.HasValue(data)) Object.assign(this, data);

    if (data.paymentType === 'A') {
      this.paymentType = 'Account';
    } else if (data.paymentType === 'C') {
      this.paymentType = 'Cash';
    } else if (data.paymentType === 'X') {
      this.paymentType = 'Credit Card';
    }
  }
}

export class ZendiTicketItemData extends EntityData {
  ticketId: number;
  productId: number;
  quantity: number;
  isPrimaryProduct: boolean;
  unitOfMeasureId: number;
  slump?: number;
  productUse: string;
  saleTypeSectionId: number;
}
//#endregion

//#region Order Dashboard
export class DeliveredTotalData {
  deliveredTotal: number;
}

export class TicketedTotalData {
  ticketedTotal: number;
}

export class OrderDashboardOrderData extends EntityData {
  dateTime: Date;
  loadDateTime: Date;
  number: number;
  customerId?: number;
  orderStateId: number;
  orderStateName: string;
  orderStateColor: string;
  isCompletedState: boolean;
  isCancelledState: boolean;
  fromLocationId: number;
  fromLocationCode: string;
  travelTime?: number;
  loadTime?: number;
  spacing?: number;
  estimatedTimeAtJob: number;
  deliveredQuantity: number;
  quantity?: number;
  
  primaryProduct: string;
  unitOfMeasureCode: string;
  deliveryAddressName: string;
  deliveryAddressStreet: string;
  deliveryAddressCity: string;
  deliveryAddressState: string;
  deliveryAddressZip: string;

  showTickets?: boolean;
  deliveryStatus: string;
  tickets?: OrderDashboardTicket[];
}

export class OrderDashboardTicket extends TicketDashboardData {
  ticketState?: ZendiTicketStateData;
  stateDuration?: number;
  dashboardStyle?: any;
  ticketChipColor?: string;

  constructor(data: TicketDashboardData) {
    super();
    Object.assign(this, data);

    this.ticketChipColor = this.getTicketChipColor();
  }

  getTicketChipColor(): string {
    return '';
  }
}

export class OrderDashboardOrder extends OrderDashboardOrderData {
  tickets?: OrderDashboardTicket[];
  showTickets?: boolean;
  startTimeFormatted?: string;
  loadTimeFormatted?: string;
  dateTimeFormatted?: string;
  spacing?: number;
  travelTime?: number;
  summary: string;

  constructor(data: OrderDashboardOrderData) {
    super();
    Object.assign(this, data);

    const jsLoadDate = new Date(data.loadDateTime);
    this.loadTimeFormatted = isNaN(jsLoadDate.getTime())
      ? ''
      : DateTime.fromJSDate(jsLoadDate).toFormat(DateFormat.Time24Simple);

    const jsStartDate = new Date(this.dateTime);
    this.startTimeFormatted = DateTime.fromJSDate(jsStartDate).toFormat(
      DateFormat.Time24Simple
    );

    this.spacing = !this.spacing ? 0 : this.spacing;
    this.travelTime = !this.travelTime ? 0 : this.travelTime;

    this.summary = Utility.IsNullOrUndefined(data.deliveryAddressCity)
      ? ''
      : `${data.deliveryAddressName ?? ''} ${
          data.deliveryAddressStreet ?? ''
        }, ${data.deliveryAddressCity ?? ''}`.trim();
  }
}

export class OrderDashboardRecord {
  id?: number;
  isExpanded?: boolean;
  order?: OrderDashboardOrder;
  tickets?: OrderDashboardTicket[];
}
//#endregion

export class ItemSectionData {
  saleTypeSection: ZendiSaleTypeSectionData;
  name: string;
  sortOrder: number;
  products: ZendiProductData[];
  items: ItemFormData[] = [];

  constructor(sts: ZendiSaleTypeSectionData, products: ZendiProductData[]) {
    this.saleTypeSection = sts;
    this.sortOrder = sts.sortOrder;
    this.name = sts.name;
    this.products = products;
  }
}

export class ItemFormData extends EntityData {
  product: ZendiProductData = null;
  quantity: number = null;
  unitOfMeasureId: number = null;
  slump: number = null;
  productUse: string = null;
  isPrimaryProduct: boolean;
}

export class AddressFormData {
  address_Name: string;
  address_Street: string;
  address_City: string;
  address_State: string;
  address_Zip: string;
  address_Latitude: string;
  address_Longitude: string;
  address_County: string;
  taxRate: ZendiTaxRateData;
}

// #region Authorization & User Models
export class UserData extends EntityData {
  username: string;
  firstName: string;
  lastName: string;
  customerName: string;
  customerId: number;
  roles: RoleData[];
}

export class RoleData extends EntityData {
  name: string;
  isAdmin: boolean;
  isActive: boolean;
  permissions: PermissionData[];
}

export class PermissionData extends EntityData {
  roleId: number;
  routeId: number;
  readAccess: boolean;
  writeAccess: boolean;
}

export class RouteData extends EntityData {
  path: string;
  alias: string;
  readonly: boolean;
  sort?: number;
}

export class AccountData {
  auth0User: User;
  mccUser: UserData;
  isAuthenticated: boolean;
}
