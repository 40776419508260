export const ShortNumericColumnWidth = '10ch';
export const LongNumericColumnWidth = '15ch';
export const LongStringColumnWidth = '45ch';
export const ShortStringColumnWidth = '30ch';

export const States = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Marshall Islands', abbreviation: 'MH' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Palau', abbreviation: 'PW' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virgin Islands', abbreviation: 'VI' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

export enum DialogSize {
  sm = '24em',
  md = '64em',
  lg = '100%',
  auto = 'auto',
}

export class Utility {
  static IsNullOrUndefined = (value: any) =>
    value === null || value === undefined;

  static HasValue = (value: any) => !Utility.IsNullOrUndefined(value);

  static sortAutoCompleteOptions = (
    filterVal: string,
    options: any[],
    qualifiedProperties: string[],
    defaultSortProperty: string
  ): any[] => {
    // Sort: First list items equal to filter val,
    // then list items starting with filter val,
    // then list items by code in ascending order.
    return options?.sort((a, b) => {
      const aEquals = qualifiedProperties.some(
        (property) => a[property]?.toString()?.toLowerCase() == filterVal
      );
      const bEquals = qualifiedProperties.some(
        (property) => b[property]?.toString()?.toLowerCase() == filterVal
      );
      const aStartsWith = qualifiedProperties.some((property) =>
        a[property]
          ?.toString()
          ?.toLowerCase()
          ?.startsWith(filterVal.toLowerCase())
      );
      const bStartsWith = qualifiedProperties.some((property) =>
        b[property]
          ?.toString()
          ?.toLowerCase()
          ?.startsWith(filterVal.toLowerCase())
      );

      const aScore = aEquals ? 1 : aStartsWith ? 0.5 : 0;
      const bScore = bEquals ? 1 : bStartsWith ? 0.5 : 0;

      if (aScore > bScore) return -1;
      else if (aScore < bScore) return 1;

      const aInt = Number(a[defaultSortProperty]);
      const bInt = Number(b[defaultSortProperty]);
      const aVal = a[defaultSortProperty];
      const bVal = b[defaultSortProperty];

      if (isNaN(aInt) && isNaN(bInt))
        if (!aVal) return 1;
        else if (!bVal) return -1;
        else return aVal.localeCompare(bVal);
      else if (isNaN(aInt)) return 1;
      else if (isNaN(bInt)) return -1;

      return aInt - bInt;
    });
  };

  static filterAutoCompleteOptions = (
    filterVal: string,
    options: any[],
    qualifiedProperties: string[],
    defaultSortProperty: string,
    showAllByDefault: boolean = false,
    currentValue: any = null,
    currentValueQualifier: (option) => void = function () {}
  ): any[] => {
    if (!filterVal && showAllByDefault) filterVal = '';

    if (typeof filterVal !== 'string' || !options) return;

    filterVal = filterVal?.toLowerCase();

    const qualifiedOptions = filterVal
      ? options.filter((option) =>
          qualifiedProperties.some((prop) =>
            option[prop]?.toString()?.toLowerCase()?.includes(filterVal)
          )
        )
      : options;

    // If a current value exists and isn't already in the list then add it to the list to allow re-selection.
    if (
      Utility.HasValue(currentValue) &&
      !qualifiedOptions.some((option) => currentValueQualifier(option))
    ) {
      qualifiedOptions.push(currentValue);
    }

    return Utility.sortAutoCompleteOptions(
      filterVal,
      qualifiedOptions,
      qualifiedProperties,
      defaultSortProperty
    );
  };
}
