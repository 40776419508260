import { Inject, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RouteData, UserData } from '../models';
import { HttpClient } from '@angular/common/http';
import { ENV_CONFIG, EnvironmentConfig } from '../environment.config';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ApiService {
  //Subjects: anything that subscribes will get the latest version of the subject
  private usersSubject: BehaviorSubject<UserData[]> = new BehaviorSubject<
    UserData[]
  >(null);

  //Subject Observables: allows components to subscribe to changes of the subject.
  public get users(): Observable<UserData[]> {
    return this.usersSubject;
  }

  private dataUrl: {
    user: string;
    route: string;
  };

  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) protected config: EnvironmentConfig
  ) {
    super(http, config);

    /* DEBUG */
    console.log(config);

    this.dataUrl = {
      user: `${this.config.environment.apiUrl}/user`,
      route: `${this.config.environment.apiUrl}/route`,
    };
  }

  // API calls
  //#region User
  public getUsers() {
    this.getDataRecords<UserData>(
      `${this.dataUrl.user}/GetUsers`,
      this.usersSubject,
      UserData
    );
  }

  // Dev note: Avoid making changes to the api of this call as this is used for login
  public getUserByUsername(username: string = null) {
    return this.http.get<UserData>(
      `${this.dataUrl.user}/GetUserByUsername?username=${username}`
    );
  }

  public getUserById(id: number) {
    return this.getDataRecord<UserData>(`${this.dataUrl.user}/GetUser?id=${id}`, null, UserData)
  }
  //#endregion

  public getRoutes() {
    return this.getDataRecords<RouteData>(
      `${this.dataUrl.route}/GetRoutes`,
      null,
      RouteData
    );
  }
}
