<div class="mat-dialog-header" *ngIf="heading">
    <h1
      class="mat-dialog-title"
      mat-dialog-title
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle
      cdkDragBoundary=".cdk-overlay-container"
      *ngIf="isDraggable"
    >
      {{ heading }}
    </h1>
    <h1 class="mat-dialog-title" mat-dialog-title *ngIf="!isDraggable">
      {{ heading }}
    </h1>
    <button type="button" aria-label="Close dialog" mat-icon-button color="default" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <ng-template #content></ng-template>
  </div>
  <div *ngIf="showActions" mat-dialog-actions class="flex-end">
    <button mat-button [mat-dialog-close]="false" type="button">
      {{ cancelText }}
    </button>
    <button *ngIf="confirmText" [mat-dialog-close]="false" mat-raised-button color="primary">
      {{ confirmText }}
    </button>
  </div>