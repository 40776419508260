import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Title } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  PropertyName,
  RecordAction,
  RecordActionSet,
  RecordListModel,
} from '../models';
import { Utility } from '../constants';
import { MessageService } from '../message.service';
import { takeUntil } from 'rxjs';

export class RecordListEvent<T> {
  constructor(public value: T, public index: number = null) {}
}

@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.scss'],
})
export class RecordListComponent<T>
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  //#region Inputs
  @Input() isDraggable: boolean = false;
  @Input() showFilter: boolean = true;
  @Input() showPaginator: boolean = true;
  @Input() isElevated: boolean = true;
  @Input() confirmDelete: boolean = true;
  @Input() showCloseControl: boolean = false;
  @Input() isLoading: boolean;
  @Input() newButtonText: string = 'New';
  @Input() heading: string = '';
  @Input() noRecordsMessage: string = '';
  @Input() pageSizeOptions: number[] = [50, 75, 100, 250];
  @Input() availableActions: RecordActionSet = {};
  @Input() recordProperties: PropertyName[] = [];
  @Input() data: RecordListModel<T>;
  @Input() dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
  @Input() getRecordName = (record: T) => {
    return null;
  };
  @Input() trackBy: TrackByFunction<T> = (index: number, record: T) => index;
  //#endregion

  //#region Outputs
  @Output() create: EventEmitter<T> = new EventEmitter<T>();
  @Output() delete: EventEmitter<RecordListEvent<T>> = new EventEmitter<
    RecordListEvent<T>
  >();
  @Output() view: EventEmitter<RecordListEvent<T>> = new EventEmitter<
    RecordListEvent<T>
  >();
  @Output() edit: EventEmitter<RecordListEvent<T>> = new EventEmitter<
    RecordListEvent<T>
  >();
  @Output() select: EventEmitter<T> = new EventEmitter<T>();
  @Output() selectRow: EventEmitter<T> = new EventEmitter<T>();
  //#endregion

  defaultSort: string = '';
  columns: string[] = [];
  propertyWidths: string[] = [];
  propertyCodeNames: string[] = [];
  propertyDisplayNames: string[] = [];
  actions: RecordAction;
  originalData: T[] = []; //unfiltered data

  constructor(
    protected titleService: Title,
    protected messageService: MessageService
  ) {
    super(titleService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.titleService.setTitle(`${this.heading ? this.heading : 'MCC App'}`);

    this.dataSource.filterPredicate = this.filterPredicate;
    this.dataSource.filter = '*';

    this.setProperties();
    this.defineTableColumns();
    this.defineExtraColumns();

    if (!this.pageSizeOptions || !this.pageSizeOptions.length)
      this.showPaginator = false;
  }

  ngAfterViewInit(): void {
    this.resetPaginator;
  }

  ngOnChanges(changes: any) {
    if (changes.data) this.setProperties();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onRecordAction(
    event: Event,
    action: RecordAction,
    record: T = null,
    index: number = null
  ) {
    event?.preventDefault();
    event?.stopPropagation();

    switch (action) {
      case RecordAction.Delete:
        if (this.confirmDelete) {
          this.messageService
            .openDeleteDialog(this.getRecordName(record))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              (result: boolean) => result && this.onDelete(record, index)
            );
        } else {
          this.onDelete(record, index);
        }
        break;
      case RecordAction.Create:
        this.onCreate(record);
        break;
      case RecordAction.View:
        this.onView(record, index);
        break;
      case RecordAction.Edit:
        this.onEdit(record, index);
        break;
      case RecordAction.Select:
        this.onSelect(record);
        break;
      case RecordAction.SelectRow:
        if (this.availableActions.selectRow) this.onSelect(record);
        break;
      default:
        console.error('Invalid Action');
        break;
    }
  }

  //#region Override Methods
  onDelete(record: T, index: number) {
    this.delete.emit(new RecordListEvent(record, index));
  }
  onView(record: T, index: number) {
    this.view.emit(new RecordListEvent(record, index));
  }
  onEdit(record: T, index: number) {
    this.edit.emit(new RecordListEvent(record, index));
  }
  onCreate(record: T) {
    this.create.emit(record);
  }
  onSelect(record: T) {
    this.select.emit(record);
  }

  protected loadData() {
    this.isLoading = true;
    this.titleService.setTitle(this.heading);
  }
  //#endregion

  resetPaginator() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.showPaginator ? this.paginator : null;

    // move to first page if not already there
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  protected filterPredicate = (data: any, filter: string) => {
    const transformedFilter = filter.trim().toLowerCase();

    return (
      filter === '*' ||
      this.recordProperties.some((p) =>
        Utility.HasValue(
          data[p.codeName] &&
            p.applyFilter &&
            `${data[p.codeName]}`.toLowerCase().indexOf(transformedFilter) !==
              -1
        )
      )
    );
  };

  applyFilter(event: Event) {
    const filterVal = (event.target as HTMLInputElement).value;
    const filterValTrimmed = filterVal.trim().toLowerCase();
    this.dataSource.filter = filterValTrimmed.length ? filterValTrimmed : '*';
    this.resetPaginator();
  }

  protected defineTableColumns() {
    // Use only properties that are not marked as hidden
    const visibleRecordProps = this.recordProperties.filter(
      (rp) => !rp.isHiddenProperty
    );

    // Set columns
    this.propertyWidths = visibleRecordProps.map((c) => c.width || 'auto');
    this.propertyCodeNames = visibleRecordProps.map((c) => c.codeName);
    this.propertyDisplayNames = visibleRecordProps.map((c) => c.displayName);
    this.columns = this.propertyCodeNames.slice();
  }

  /**
   * Add an additional column to the table to display actions.
   */
  protected defineExtraColumns() {
    const colActions = Object.entries(this.availableActions).filter(
      ([k, v]) => k !== 'create'
    );

    if (colActions.some(([k, v]) => v === true)) this.columns.push('actions');
  }

  protected sortDataAccessor(item: T, property: string) {
    // Property is a number
    const n = Number(item[property]);
    if (!isNaN(n)) return n;

    // Property is date
    const d = Date.parse(item[property]);
    if (!isNaN(n)) return d;

    return item[property];
  }

  protected setProperties() {
    if (!this.data) return;

    // Set custom implementation on MatTableDataSource
    this.dataSource = this.data.dataSource;
    this.dataSource.filterPredicate = this.filterPredicate;
    this.dataSource.sortingDataAccessor = this.sortDataAccessor;

    this.recordProperties = this.data.recordProperties || this.recordProperties;
    this.defineTableColumns();
    this.defineExtraColumns();

    this.getRecordName = this.data.getRecordName || this.getRecordName;
    this.trackBy = this.data.trackBy || this.trackBy;
    this.heading = this.data.heading ?? this.heading;
    this.newButtonText = this.data.newButtonText || this.newButtonText;
    this.pageSizeOptions = this.data.pageSizeOptions || this.pageSizeOptions;
    this.isLoading = this.data.isLoading || this.isLoading;
    this.availableActions = this.data.availableActions || this.availableActions;
    this.confirmDelete = Utility.HasValue(this.data.confirmDelete)
      ? this.data.confirmDelete
      : this.confirmDelete;
    this.showFilter = Utility.HasValue(this.data.showFilter)
      ? this.data.showFilter
      : this.showFilter;
    this.showPaginator = Utility.HasValue(this.data.showPaginator)
      ? this.data.showPaginator
      : this.showPaginator;
    this.isElevated = Utility.HasValue(this.data.isElevated)
      ? this.data.isElevated
      : this.isElevated;
  }
}
