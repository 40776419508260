export const environment = {
  production: true,
  enableAuth: true,
  navText: 'ZendiConnect (Dev)',
  version: '1.00',
  clientId: 'f1BzYG1OhtKSP2ezV7OiykQzv0bDOyaf', // Auth0 WEB Client Id
  authority: 'zendi-connect.us.auth0.com', // Auth0 Tenant
  authorityId: 'zendi-connect-api-1', // Auth0 API Audience
  apiUrl: 'https://zendi-connect-dev-api.azurewebsites.net/v1', // App API Address
  resourceScope: 'api://cb3f5e55-c0ef-4028-8d1e-5de94fc99c0d/access_as_user', // API Client Id
  redirectUrl: 'https://dev.zendi-connect.murphyinc.org', // Alt: 'https://zendi-connect-dev-web.azurewebsites.net'
  loginScopes: ['user.read', 'openid', 'profile'],
  log: {
    none: true,
    all: false,
    checked: false,
    changes: false,
    initDestroy: false,
    viewContent: false,
  },
};
  