<!-- Mobile Navigation Container -->
<mat-sidenav-container class="mobile-sidenav-container" (backdropClick)="mobileSidenav.toggle()">
  <mat-sidenav #mobileSidenav mode="over" class="mobile-sidenav" position="end" autoFocus="false" opened="false"
    fixedInViewport="true">
    <div class="mobile-sidenav-menu">
      <div *ngIf="isAuthenticated" class="navigation-message-bar mobile">
        {{ userInformation }}
      </div>
      <div class="routing-menu">
        <ng-container *ngTemplateOutlet="routingAndNavigation"></ng-container>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content></mat-sidenav-content>
</mat-sidenav-container>

<!-- Navigation -->
<nav class="main-navigation">
  <!-- Logo -->
  <a class="logo-container" routerLink="/">
    <img class="logo" alt="mcc-app" src="../assets/mccLogo.png" />
  </a>

  <!-- Navigation Section -->
  <div class="navbar-container">
    <!-- Desktop Navbar -->
    <div class="navigation-message-bar desktop" *ngIf="isAuthenticated">
      <div class="user-welcome">{{ userInformation }}</div>
    </div>
    <mat-toolbar class="navigation-toolbar desktop">
      <ng-container *ngTemplateOutlet="routingAndNavigation"></ng-container>
    </mat-toolbar>

    <!-- Mobile Navbar -->
    <mat-toolbar class="navigation-toolbar mobile">
      <button mat-icon-button class="mobile-navigation-menu-button" (click)="mobileSidenav.toggle()">
        <mat-icon class="mobile-navigation-menu-icon">menu</mat-icon>
      </button>
    </mat-toolbar>
  </div>
</nav>

<!-- Menu Section -->
<ng-template #routingAndNavigation>
  <div class="routing-container">
    <!-- Logged In Users Section -->
    <button *ngIf="showLoggedInOptions" mat-button [color]="navbarButtonColor" [matMenuTriggerFor]="operationsMenu">
      Operations
    </button>
    <mat-menu #operationsMenu="matMenu" class="operation-routes" [hasBackdrop]="true">
      <div *ngFor="let route of operationRoutes">
        <a mat-menu-item routerLinkActive="link-active" routerLink="{{ route.path }}" (click)="onMenuSelection()">
          {{ route.alias }}
        </a>
      </div>
    </mat-menu>

    <!-- Logged In Section (stored in DB) -->
    <div *ngFor="let route of loggedInRoutes" class="logged-in-routes">
      <button *ngIf="showLoggedInOptions" mat-button [color]="navbarButtonColor" routerLinkActive="link-active"
        routerLink="{{ route.path }}" (click)="onMenuSelection()">
        {{ route.alias }}
      </button>
    </div>

    <!-- Logged Out Users Section -->
    <button *ngIf="showLoggedOutOptions" mat-button [color]="navbarButtonColor" (click)="onMenuSelection()">
      Products
    </button>

    <!-- All Users Section -->
    <button *ngIf="!isAuthenticationLoading" mat-button [color]="navbarButtonColor" (click)="onMenuSelection()">
      Contact Us
    </button>
  </div>

  <div class="login-container">
    <div class="logged-out-user-display" *ngIf="showLoggedOutOptions">
      <button mat-flat-button color="primary" (click)="login()">Log In</button>
    </div>
    <div class="logged-in-user-display" *ngIf="showLoggedInOptions">
      <button mat-flat-button (click)="logout()">Log Out</button>
    </div>
  </div>
</ng-template>