import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageService } from '../../message.service';
import { OperationService } from '../../entity-data-service/operation.service';
import { AuthorizationService } from '../../authorization/authorization.service';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../base/base.component';
import { Division } from '../../models';

export enum OrderType {
  Aggregate = 'Aggregate',
  Concrete = 'Concrete',
}

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrl: './place-order.component.scss',
})
export class PlaceOrderComponent extends BaseComponent {
  protected isLoading: boolean = true;
  protected orderTypes = OrderType;
  protected backgroundClass = 'divisions-background';

  protected divisions: Division[] = [];
  protected aggregateDivision: Division;
  protected concreteDivision: Division;

  constructor(
    protected titleService: Title,
    protected messageService: MessageService,
    protected operationService: OperationService,
    protected authService: AuthorizationService,
    protected route: ActivatedRoute
  ) {
    super(titleService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscribeToServices();
  }

  subscribeToServices() {
    // Divisions
    this.operationService.divisions.subscribe((divisions: Division[]) => {
      this.isLoading = false;

      this.divisions = divisions;
      this.aggregateDivision = this.divisions.find(
        (d) => d.name === OrderType.Aggregate
      );
      this.concreteDivision = this.divisions.find(
        (d) => d.name === OrderType.Concrete
      );

      if (this.divisions.some(d => !d.isActive)) this.backgroundClass = 'division-background';

      console.log(this.backgroundClass)
    });

    if (!this.divisions.length) this.operationService.getDivisions();
  }

  protected openOrderForm(type: OrderType) {
    const division = this.divisions.find(d => d.name === type);
    this.openInactiveDivisionMessage(division);
    /* DEBUG */
    console.log('DEBUG: Opening Order form for Division:', division);
  }

  private openInactiveDivisionMessage(division: Division) {
    if (!division?.isActive)
      this.messageService.openSnackBar(
        `${division.name} Orders cannot be placed at this time.`,
        'Information'
      );
  }
}
