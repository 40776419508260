import { Component, Input } from '@angular/core';
import { FormComponent } from '../../form/form.component';
import {
  AddressFormData,
  DateFormat,
  ItemFormData,
  ItemSectionData,
  UserData,
  ZendiProductData,
  ZendiSaleTypeSectionData,
  ZendiTicket,
  ZendiTicketItemData,
} from '../../models';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { OperationService } from '../../entity-data-service/operation.service';
import { MessageService } from '../../message.service';
import { combineLatest, skipWhile, takeUntil } from 'rxjs';
import { Utility } from '../../constants';
import { DateTime, Interval } from 'luxon';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent extends FormComponent<ZendiTicket> {
  @Input() user: UserData;

  isLoading: boolean = false;
  isHandset: boolean;
  hasActualJobTime: boolean = false;
  gridClass: string = '';
  defaultState: string = '';
  actualJobTimeTooltip: string = '';
  products: ZendiProductData[] = [];
  saleTypeSections: ItemSectionData[] = [];
  ticketItems: ZendiTicketItemData;
  addressFormData: AddressFormData;

  constructor(
    protected titleService: Title,
    protected formBuilder: UntypedFormBuilder,
    protected operationService: OperationService,
    protected messageService: MessageService
  ) {
    super(formBuilder, titleService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isHandset = this.messageService.isHandset$['_value'];
    this.defaultState = 'WI';

    this.initForm();
    this.subscribeToServices();
  }

  subscribeToServices() {
    this.operationService.zendiLocations
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data)
      )
      .subscribe((records) => {
        const ticketLocation = records.find(
          (r) => r.id === this.initRecord.fromLocationId
        );
        if (Utility.HasValue(ticketLocation)) {
          const name = `${ticketLocation.code} - ${ticketLocation.name}`;
          this.formGroup.controls.location.setValue(name);
        }
      });

    this.operationService.zendiTaxRates
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data)
      )
      .subscribe((records) => {
        const taxRate = records.find((r) => r.id === this.initRecord.taxRateId);

        if (Utility.HasValue(taxRate)) {
          const name = `${taxRate.code} - ${taxRate.description}`;
          this.formGroup.controls.taxRate.setValue(name);
        }
      });

    this.operationService.zendiQuotes
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data)
      )
      .subscribe((records) => {
        if (records.length && this.initRecord.quoteId) {
          const quote = records?.find((r) => r.id === this.initRecord?.quoteId);
          this.formGroup.controls.quote.setValue(`${quote.id} - ${quote.name}`);
        }
      });

    combineLatest([
      this.operationService.zendiSaleTypeSections,
      this.operationService.zendiProducts,
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data.every((e) => e))
      )
      .subscribe(([saleTypeSections, products]) => {
        this.products = products;
        this.initSaleTypeSections(saleTypeSections);
      });

    this.operationService.getZendiLocations();
    this.operationService.getZendiProducts();
    this.operationService.getZendiTaxRates();
    this.operationService.getZendiSaleTypeSections();
    this.operationService.getZendiQuotes(this.user.customerId);
  }

  protected initForm(): void {
    let loadDate = '--';
    let loadTime = '--';
    let loadDateObj = this.getDateTimeObj(this.initRecord.loadStartDateTime);
    if (loadDateObj !== null) {
      loadDate = loadDateObj.toFormat(DateFormat.DateShort);
      loadTime = loadDateObj.toFormat(DateFormat.Time24Simple);
    }

    const onJobDateObj = this.getDateTimeObj(this.initRecord.dateTime);
    const onJobDate = onJobDateObj.toFormat(DateFormat.DateShort);
    const onJobTime = onJobDateObj.toFormat(DateFormat.Time24Simple);

    this.formGroup = this.formBuilder.group({
      location: new UntypedFormControl('--'),
      equipment: new UntypedFormControl(this.initRecord.equipment || '--'),
      customer: new UntypedFormControl(this.user.customerName || '--'),
      quote: new UntypedFormControl('--'),
      paymentType: new UntypedFormControl(this.initRecord.paymentType),
      customerJob: new UntypedFormControl(this.initRecord.customerJob || '--'),
      customerPurchaseOrder: new UntypedFormControl(
        this.initRecord.customerPurchaseOrder || '--'
      ),
      taxRate: new UntypedFormControl('--'),
      loadDate: new UntypedFormControl(loadDate),
      loadTime: new UntypedFormControl(loadTime),
      onJobDate: new UntypedFormControl(onJobDate),
      onJobTime: new UntypedFormControl(onJobTime),
      actualJobTime: new UntypedFormControl(),
    });

    this.setActualJobTime();

    // Ticket form is read only
    this.readonly = true;
    this.formGroup.disable();

    this.addressFormData = {
      address_City: this.initRecord?.deliveryAddressCity,
      address_County: null,
      address_Latitude: this.initRecord?.deliveryAddressLatitude?.toString(),
      address_Longitude: this.initRecord?.deliveryAddressLongitude?.toString(),
      address_Name: this.initRecord?.deliveryAddressName,
      address_State: this.initRecord?.deliveryAddressState,
      address_Street: this.initRecord?.deliveryAddressStreet,
      address_Zip: this.initRecord?.deliveryAddressZip,
      taxRate: null,
    };
  }

  protected onClose = () => this.formCancel.emit();

  getDateTimeObj(date: Date) {
    if (Utility.IsNullOrUndefined(date)) return null;

    return DateTime.fromJSDate(new Date(date));
  }

  /**
   * Create a DateTime object in utc.
   * Then formats the object in a Central Standard Time string.
   * @param initDate
   * @returns formatted date string
   */
  getFormattedUtcDate(initDate: Date) {
    if (Utility.IsNullOrUndefined(initDate)) return '';

    const date = new Date(initDate);
    const utcDateTime = DateTime.utc(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );

    return utcDateTime?.setZone('CST')?.toFormat(DateFormat.TimeWithLongOffset);
  }

  /**
   * Calculate how long it took for a job to finish once the equipment
   * is at the Job until the end of teh job.
   */
  setActualJobTime() {
    const atJobTimestamp = this.initRecord.atJobTimestamp;
    const endJobTimestamp = this.initRecord.endJobTimestamp;
    if (Utility.HasValue(atJobTimestamp) && Utility.HasValue(endJobTimestamp)) {
      this.hasActualJobTime = true;

      let atJobTimeObj = this.getDateTimeObj(atJobTimestamp);
      let endJobTimeObj = this.getDateTimeObj(endJobTimestamp);

      const diffInMins = Math.ceil(
        Interval.fromDateTimes(atJobTimeObj, endJobTimeObj).length('minutes')
      );
      this.formGroup.controls.actualJobTime?.setValue(diffInMins);

      const atJobTime = this.getFormattedUtcDate(atJobTimestamp);
      const endJobTime = this.getFormattedUtcDate(endJobTimestamp);

      this.actualJobTimeTooltip = `Actual On Job Time:\n ${atJobTime}\n Actual End Job Time:\n ${endJobTime}`;
    }
  }

  initSaleTypeSections(records: ZendiSaleTypeSectionData[]) {
    const hasTicketItems = this.initRecord.ticketItems?.length;

    const saleTypeSections = records?.filter((r) =>
      this.initRecord.ticketItems.some((ti) => ti.saleTypeSectionId === r.id)
    );

    this.saleTypeSections = saleTypeSections
      ?.map((sts) => {
        const section = new ItemSectionData(sts, this.products);

        section.items = hasTicketItems
          ? this.initRecord.ticketItems
              ?.filter((ti) => ti.saleTypeSectionId === sts.id)
              ?.map((ti) => {
                const product = this.products.find(
                  (p) => p.id === ti.productId
                );

                const itemFormData: ItemFormData = Object.assign(
                  new ItemFormData(),
                  {
                    id: ti.id,
                    rowVersion: ti.rowVersion,
                    product: product,
                    quantity: ti.quantity,
                    unitOfMeasureId: ti.unitOfMeasureId,
                    slump: ti.slump,
                    productUse: ti.productUse,
                    isPrimaryProduct: ti.isPrimaryProduct,
                  }
                );

                return itemFormData;
              })
              ?.sort((a, b) =>
                a.isPrimaryProduct ? -1 : b.isPrimaryProduct ? 1 : 0
              )
          : [];

        return section;
      })
      ?.sort((a, b) => a.sortOrder - b.sortOrder);
  }
}
