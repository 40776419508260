import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Title } from '@angular/platform-browser';
import { AuthorizationService } from '../authorization/authorization.service';
import { AccountData, RouteData } from '../models';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from '../entity-data-service/user.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent {
  @ViewChild('mobileSidenav') mobileSideNav: MatSidenav;

  appName: string = '';
  operationRoutes: RouteData[] = [];
  loggedInRoutes: RouteData[] = [];

  /* Authentication Service Statuses */
  protected get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }
  protected get isLoggedIn(): boolean {
    return this.authService.isVerified;
  }
  protected get isAuthenticationLoading(): boolean {
    return this.authService.isAuthenticationLoading;
  }
  // todo: once permissions become more granular, these will likely have to be removed
  protected get showLoggedInOptions(): boolean {
    return !this.isAuthenticationLoading && this.isLoggedIn;
  }
  protected get showLoggedOutOptions(): boolean {
    return !this.isAuthenticationLoading && !this.isLoggedIn;
  }
  protected get userAccount(): AccountData {
    return this.authService.verifiedAccountData;
  }
  protected get userInformation(): string {
    return this.isLoggedIn
      ? `Welcome, ${this.userAccount.mccUser?.firstName}`
      : '';
  }
  protected get navbarButtonColor(): 'primary' | 'secondary' {
    return this.isLoggedIn ? 'primary' : 'secondary';
  }

  constructor(
    protected override titleService: Title,
    private authService: AuthorizationService,
    protected userService: UserService
  ) {
    super(titleService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.appName = 'zendi-connect-test';
  }

  ngAfterViewInit() {
    this.userService
      .getRoutes()
      .pipe(take(1))
      .subscribe((data: RouteData[]) => {
        this.operationRoutes = data
          .filter((r) => r.path.includes('operations'))
          .sort((a, b) => a.sort - b.sort);

        this.loggedInRoutes = data.filter(
          (r) => !r.path.includes('operations')
        );
      });
  }

  protected onMenuSelection(): void {
    if (this.mobileSideNav.opened) this.mobileSideNav.close();
  }

  public login(): void {
    this.authService.login();
  }

  public logout(): void {
    this.authService.logout();
  }
}
