<form [formGroup]="formGroup">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container"
    [cdkDragDisabled]="isHandset" class="ticket-header">
    <div class="ticket-header-details">
      <h1>{{initRecord.id}} {{user.customerName}}</h1>
      <div class="ticket-tags">
        <mat-chip>{{initRecord.saleType}}</mat-chip>
        <mat-chip>{{initRecord.ticketStateName}}</mat-chip>
      </div>
    </div>
    <div>
      <button type="button" aria-label="Close dialog" mat-icon-button color="default" (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="small-form-field-fill ticket-content">
    <div class="ticket-grid">
      <div class="section-header">Ticket Info</div>
      <div>
        <div class="info-row">
          <mat-form-field class="flex-wide">
            <mat-label>From Location</mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>
        </div>
        <div class="info-row" *ngIf="initRecord.equipment?.length">
          <mat-form-field class="flex-wide">
            <mat-label>Equipment</mat-label>
            <input matInput formControlName="equipment" />
          </mat-form-field>
        </div>
        <div class="info-row">
          <mat-form-field class="flex-wide">
            <mat-label>Customer</mat-label>
            <input matInput formControlName="customer" />
          </mat-form-field>
        </div>
        <div class="info-row">
          <mat-form-field class="flex-wide">
            <mat-label>Quote</mat-label>
            <input matInput formControlName="quote" />
          </mat-form-field>
          <mat-form-field class="flex-wide">
            <mat-label>Payment</mat-label>
            <input matInput formControlName="paymentType" />
          </mat-form-field>
        </div>
        <div class="info-row">
          <mat-form-field class="flex-wide">
            <mat-label>Customer Job</mat-label>
            <input matInput formControlName="customerJob" />
          </mat-form-field>
          <mat-form-field class="flex-wide">
            <mat-label>Customer PO</mat-label>
            <input matInput formControlName="customerPurchaseOrder" />
          </mat-form-field>
        </div>
        <app-address [initRecord]="addressFormData" [required]="false" [hideLatLong]="false" [readonly]="true"
          [defaultState]="defaultState" [showTaxRate]="false" [hideLatLong]="true"
          [matFormFieldInputClass]="'small-form-field-fill'"></app-address>
        <div class="info-row">
          <mat-form-field class="flex-wide">
            <mat-label>Tax</mat-label>
            <input matInput formControlName="taxRate" />
          </mat-form-field>
        </div>
        <div class="info-row">
          <mat-form-field class="flex-standard">
            <mat-label>Load Date</mat-label>
            <input matInput formControlName="loadDate" />
            <mat-icon matSuffix>calendar_today</mat-icon>
          </mat-form-field>
          <mat-form-field class="flex-narrow">
            <mat-label>Load Time</mat-label>
            <input matInput formControlName="loadTime" />
          </mat-form-field>
          <mat-form-field class="flex-standard">
            <mat-label>On Job Date</mat-label>
            <input matInput formControlName="onJobDate" />
            <mat-icon matSuffix>calendar_today</mat-icon>
          </mat-form-field>
          <mat-form-field class="flex-narrow">
            <mat-label>On Job Time</mat-label>
            <input matInput formControlName="onJobTime" />
          </mat-form-field>
          <mat-form-field class="flex-standard" *ngIf="hasActualJobTime" [matTooltip]="actualJobTimeTooltip"
            matTooltipPosition="below" matTooltipClass="multi-line-tooltip">
            <mat-label>Actual Job Time</mat-label>
            <input matInput formControlName="actualJobTime" />
            <span matSuffix>min</span>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="product-grid">
      <ng-container *ngFor="let section of saleTypeSections; let i = index">
        <div class="section-header">
          {{ section.name }}
        </div>
        <div>
          <app-item *ngFor="let item of section.items; let i = index" [label]="section.name" [readonly]="readonly"
            [products]="section.products" [product]="item.product" [quantity]="item.quantity" [data]="item"
            [showSlump]="section.saleTypeSection?.showSlumpColumn"></app-item>
        </div>
      </ng-container>
    </div>
  </div>
</form>