<form [formGroup]="formGroup">
  <div class="address-row {{matFormFieldInputClass}}">
    <mat-form-field class="flex-wide ">
      <mat-label>Street</mat-label>
      <input type="search" matInput placeholder="Enter a location" autocomplete="none" formControlName="address_Street"
        maxlength="100" [required]="required" />
    </mat-form-field>

    <mat-form-field class="flex-standard compact">
      <mat-label>State</mat-label>
      <mat-select placeholder="WI" formControlName="address_State" [required]="required"
        (selectionChange)="onInputInteraction()">
        <mat-option>--</mat-option>
        <mat-option *ngFor="let state of states" [value]="state.abbreviation">
          {{ state.abbreviation }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-standard">
      <mat-label>Zip</mat-label>
      <input matInput placeholder="54911" formControlName="address_Zip" maxlength="10"
        (blur)="onInputInteraction($event)" autocomplete="none" />
    </mat-form-field>

    <mat-form-field class="flex-wide" *ngIf="showTaxRate">
      <mat-label>Tax</mat-label>
      <input matInput formControlName="taxRate" auto-tab-select [required]="required" [matAutocomplete]="autoTaxRate"
        (focus)="focusField($event.target)" />
      <mat-autocomplete class="compact" #autoTaxRate="matAutocomplete" [displayWith]="displayTaxRateValue"
        [panelWidth]="'auto'" (closed)="onTaxRateOptionsClosed()">
        <mat-option *ngFor="let option of filteredTaxRateOptions | async" [value]="option">
          {{ option.code }} - {{ option.description }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="address-row">
    <mat-form-field class="flex-wide">
      <mat-label>City</mat-label>
      <input matInput placeholder="Appleton" formControlName="address_City" maxlength="50" [required]="required"
        (blur)="onInputInteraction($event)" autocomplete="none" />
    </mat-form-field>

    <span class="lat-long-wrapper">
      <mat-form-field *ngIf="!hideLatLong" class="{{ latLongClass}}">
        <mat-label>Latitude</mat-label>
        <input matInput placeholder="43.074731" formControlName="address_Latitude" [required]="requiredLatLong"
          (blur)="onLatChange()" />
      </mat-form-field>
      <mat-form-field *ngIf="!hideLatLong" class="{{ latLongClass }} {{matFormFieldInputClass}}">
        <mat-label>Longitude</mat-label>
        <input matInput placeholder="-89.38623" formControlName="address_Longitude" [required]="requiredLatLong"
          (blur)="onLongChange()" />
      </mat-form-field>
    </span>
  </div>
</form>