import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  ZendiTicketStateData,
  UserData,
  TicketDashboardData,
  PropertyName,
  ZendiTicketData,
  ZendiTicket,
} from '../../models';
import { RecordListComponent } from '../../record-list/record-list.component';
import { Title } from '@angular/platform-browser';
import { MessageService } from '../../message.service';
import { OperationService } from '../../entity-data-service/operation.service';
import { interval, skipWhile, Subscription, take, takeUntil } from 'rxjs';
import { DateTime } from 'luxon';
import { UntypedFormControl } from '@angular/forms';
import { AuthorizationService } from '../../authorization/authorization.service';
import {
  DialogSize,
  LongNumericColumnWidth,
  ShortNumericColumnWidth,
  ShortStringColumnWidth,
  Utility,
} from '../../constants';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TicketComponent } from '../ticket/ticket.component';

@Component({
  selector: 'app-ticket-dashboard',
  templateUrl: './ticket-dashboard.component.html',
  styleUrls: [
    '../../record-list/record-list.component.scss',
    './ticket-dashboard.component.scss',
  ],
})
export class TicketDashboardComponent extends RecordListComponent<TicketDashboardData> {
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoading: boolean;
  heading: string;
  refreshInterval: number = 30;
  intervalSubscription: Subscription;
  user: UserData;
  tickets: TicketDashboardData[] = [];
  ticketsToDisplay: TicketDashboardData[];
  ticketStates: ZendiTicketStateData[] = [];
  allTicketStates: ZendiTicketStateData[] = [];
  allStatusOption: any = { id: '', statusName: 'All Statuses' };
  statusOptions: any[] = [this.allStatusOption];
  filteredStatusOptions: any[] = [this.allStatusOption];
  selectedStatus: any = this.allStatusOption;
  selectedDate: DateTime;
  noRecordsDate: string = '';
  selectedDateCtrl: UntypedFormControl = new UntypedFormControl();

  //#region Record List Params
  recordProperties: PropertyName[] = [
    new PropertyName('orderNumber', 'Order', ShortNumericColumnWidth),
    new PropertyName('loadTime', 'Load Tm', LongNumericColumnWidth),
    new PropertyName('jobTime', 'Job Tm', LongNumericColumnWidth),
    new PropertyName('product', 'Primary Product'),
    new PropertyName('quantity', 'Quantity', LongNumericColumnWidth),
    new PropertyName('equipment', 'Equipment'),
    new PropertyName('summary', 'Summary'),
    new PropertyName('status', 'Status', ShortStringColumnWidth),
  ];
  protected trackByTicket = (record: TicketDashboardData) => {
    record.id;
  };

  protected filterPredicate = (data: TicketDashboardData, filter: string) => {
    const transformedFilter = filter.trim().toLowerCase();

    // Include Ticket Id for filter search
    const propertyCodeNames = ['id', ...this.propertyCodeNames];
    const foundInOrder = propertyCodeNames.some((p: string) => {
      if (data[p] == null) {
        return false;
      }

      // Added toString() to account for numbers being searched or other data types.
      return (
        `${data[p].toString().toLowerCase()}`.indexOf(transformedFilter) !== -1
      );
    });
    return filter === '*' || foundInOrder;
  };
  //#endregion

  constructor(
    protected titleService: Title,
    protected messageService: MessageService,
    protected operationService: OperationService,
    protected authService: AuthorizationService,
    protected route: ActivatedRoute
  ) {
    super(titleService, messageService);
    this.isLoading = true;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.dataSource.filterPredicate = this.filterPredicate;
    this.dataSource.sortingDataAccessor = (record, property) =>
      record[property];

    this.user = this.authService.verifiedAccountData?.mccUser;
    this.heading = `${this.user.customerName} Tickets`;

    this.selectedDate = DateTime.now();
    this.initForm();
    this.subscribeToServices();
    this.loadData(DateTime.now());

    this.setRefreshSubscription();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.intervalSubscription.unsubscribe();
  }

  protected subscribeToServices() {
    this.operationService.dashboardTickets
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data)
      )
      .subscribe((records) => {
        this.isLoading = false;
        this.tickets = records;
        this.setData();
      });

    this.operationService.zendiTicketStates
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data)
      )
      .subscribe((records) => {
        this.allTicketStates = records;

        //group Ticket States by Workflow
        const groupedByWorkflow = records.reduce((result, ticketState) => {
          const workflowId = ticketState.ticketWorkflowId;
          if (!result[workflowId]) {
            result[workflowId] = [];
          }

          result[workflowId].push(ticketState);
          return result;
        }, {} as { [workflowId: number]: ZendiTicketStateData[] });

        // Display only tickets that are in a delivered state or greater if delivered state exits, else only tickets in completed state or greater
        for (const key in groupedByWorkflow) {
          // Sort data in ascending order by sequence
          const ticketStates = groupedByWorkflow[key]?.sort(
            (a, b) => a.sequence - b.sequence
          );

          const deliveredState = ticketStates.find((ts) => ts.isDeliveredState);
          const completedState = ticketStates.find((ts) => ts.isCompletedState);
          let allowedTicketStates: ZendiTicketStateData[] = [];

          if (Utility.HasValue(deliveredState)) {
            // All states between Delivered & Completed should be displayed
            allowedTicketStates = ticketStates.filter(
              (ts) =>
                ts.sequence >= deliveredState.sequence &&
                ts.sequence < completedState.sequence
            );

            // Any state at Completed or after will display as completed
            allowedTicketStates.push(completedState);
          } else if (Utility.HasValue(completedState)) {
            // Any state at Completed or after will display as completed
            allowedTicketStates.push(completedState);
          }

          allowedTicketStates.forEach((ts) => {
            ts.statusName = `${ts.ticketWorkflowName} - ${ts.name}`;
          });
          this.ticketStates = this.ticketStates.concat(allowedTicketStates);
          this.ticketStates = this.sortTicketStates(this.ticketStates);
        }

        this.isLoading = false;
        this.setData();
      });
  }

  initForm() {
    this.selectedDateCtrl = new UntypedFormControl(new Date());
  }

  /**
   * Refresh immediately then every x seconds
   */
  setRefreshSubscription() {
    this.intervalSubscription = interval(this.refreshInterval * 1000)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.loadData(this.selectedDate);
      });
  }

  protected loadData(selectedDate: DateTime = DateTime.now()): void {
    super.loadData();
    if (this.selectedDate.startOf('day') !== selectedDate.startOf('day')) {
      this.resetPaginator();
    }

    this.isLoading = true;
    this.operationService.getTicketDashboardTickets(this.user.customerId, selectedDate);
    if (!this.ticketStates.length) this.operationService.getZendiTicketStates();
  }

  onDateChange(datepicker) {
    if (datepicker.value !== null) {
      //reset selected status to all
      this.selectedStatus = this.allStatusOption;
      this.statusOptions = [this.allStatusOption];

      this.selectedDate = DateTime.fromJSDate(datepicker.value);
      this.loadData(this.selectedDate);
    }
  }

  onStatusChange(event: MatSelect) {
    // If no status is provided, default to All Statuses
    this.selectedStatus =
      event !== null
        ? this.statusOptions.find((so) => so.id === event.value)
        : this.allStatusOption;

    this.filterData();
  }

  onTicketView(initRecord: TicketDashboardData) {
    this.operationService
      .getZendiTicket(initRecord.id)
      .pipe(
        take(1),
        skipWhile((data) => !data)
      )
      .subscribe((record) => {
        let ticket = new ZendiTicket(record);
        ticket.equipment = initRecord.equipment;
        ticket.orderNumber = initRecord.orderNumber;
        ticket.ticketStateName = initRecord.status;

        const mainComponentData = {
          user: this.user,
        };

        this.messageService.openFormDialog<ZendiTicketData>(
          TicketComponent,
          null, //heading
          null, //confirmText
          null, //cancelText
          ticket, //record
          mainComponentData, //mainComponentData
          DialogSize.md, //dialogSize
          'no-top-padding', //panelClass
          true //disableClose
        );
      });
  }

  /**
   * Sort by Ticket Workflow then by sequence.
   * @param ticketStates
   * @returns sorted array by  workflow Id then sequence
   */
  sortTicketStates(ticketStates) {
    return ticketStates.sort((a, b) => {
      return a.ticketWorkflowId - b.ticketWorkflowId || a.sequence - b.sequence;
    });
  }

  /**
   * Filter data by Status if one is selected, else show all.
   */
  filterData() {
    let filteredRecords = this.tickets;

    // Apply selected status filter
    if (this.selectedStatus && this.selectedStatus.id) {
      filteredRecords = this.tickets
        .filter(
          (t) =>
            t.ticketStateId === this.selectedStatus.id &&
            t.ticketWorkflowId === this.selectedStatus.ticketWorkflowId
        )
        .slice(0);
    }

    this.refreshRecordList(filteredRecords);
  }

  /**
   * Compare Ticket States before refresh to current Ticket States
   * @param currTicketStates current Ticket(s) State
   * @returns status options
   */
  getUpdatedStatuses(currTicketStates: ZendiTicketStateData[] = []) {
    let updatedStatuses: any[] = [this.allStatusOption];
    let ticketStates: ZendiTicketStateData[] = this.statusOptions.slice(1);

    // Get new Ticket State
    const newTicketStates = currTicketStates.filter(
      (s) => !ticketStates.some((ss) => ss.id === s.id)
    );

    // Remove dashboard Ticket States that don't have a current Ticket
    ticketStates = ticketStates.filter((ss) =>
      currTicketStates.some((s) => s.id === ss.id)
    );

    const updatedTicketStates = this.sortTicketStates(
      ticketStates.concat(newTicketStates)
    );

    return {
      statuses: updatedStatuses.concat(updatedTicketStates),
      ticketStates: ticketStates,
    };
  }

  setData() {
    if (!this.ticketStates.length || this.isLoading) return;

    const ticketDashboardData = [];
    if (this.tickets.length) {
      // Filter Tickets so only Tickets in the allowed Ticket State or greater is displayed
      this.tickets.forEach((t) => {
        const workflowStates = this.ticketStates.filter(
          (ts) => ts.ticketWorkflowId === t.ticketWorkflowId
        );

        const completedState = workflowStates.find((ts) => ts.isCompletedState);

        if (t.ticketStateSequence >= workflowStates[0].sequence) {
          // Set the Ticket State Id & Status to Completed Status's Ticket State Id and Name because any state at or after Completed will display as Completed
          if (t.ticketStateSequence > completedState?.sequence) {
            t.ticketStateId = completedState.id;
            t.status = completedState.name;
          }
          ticketDashboardData.push(t);
        }
      });
    }
    this.tickets = ticketDashboardData;

    // Limit Status Options to "All" & current Ticket Workflow & Ticket Sate
    const currTicketStates = this.ticketStates.filter((ts) =>
      this.tickets.some((t) => t.ticketStateId === ts.id)
    );

    let updatedStatuses = this.getUpdatedStatuses(currTicketStates);
    const differentStatus = this.statusOptions
      .slice(1)
      .find(
        (so) => !updatedStatuses.ticketStates.some((us) => us.id === so.id)
      );
    const updateStatusOptions =
      Utility.HasValue(differentStatus) ||
      updatedStatuses.statuses.length !== this.statusOptions.length;

    if (this.statusOptions.length <= 1) {
      this.statusOptions = this.statusOptions.concat(currTicketStates);
    } else if (updateStatusOptions) {
      this.statusOptions = updatedStatuses.statuses;
    }

    // Reset selectedStatus if there aren't any Tickets in that status
    if (
      this.selectedStatus.id &&
      Utility.IsNullOrUndefined(
        this.statusOptions.find((s) => s.id === this.selectedStatus?.id)
      )
    ) {
      this.selectedStatus = this.allStatusOption;
    }

    this.filterData();
  }

  protected defineExtraColumns() {
    this.columns = ['actions', ...this.columns];
  }

  refreshRecordList(tickets: TicketDashboardData[] = []) {
    this.noRecordsDate = this.selectedDate.toFormat('MM/dd/yyyy');
    this.dataSource.data = tickets.sort((a, b) => {
      return (
        new Date(a.loadStartDateTime).getTime() -
        new Date(b.loadStartDateTime).getTime()
      );
    });
  }
}
