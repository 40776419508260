import {
  Component,
  ComponentRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Utility } from '../../constants';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent<C, T> implements OnInit, OnDestroy {
  @ViewChild('content', { read: ViewContainerRef, static: true })
  contentComponentVC: ViewContainerRef;
  @Input() contentComponentRef: ComponentRef<C>;
  @Input() mainComponent: Type<C>;
  @Input() mainComponentData: any = {};
  @Input() isFormComponent: boolean = false;
  @Input() confirmText: string = null;
  @Input() cancelText: string = 'Cancel';
  @Input() heading: string = null;
  @Input() showActions: boolean = true;
  @Input() closeOnSubmit: boolean = true;
  @Input() isDraggable: boolean = true;

  record$: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent<C, T>>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    Object.assign(this, this.data);

    this.contentComponentRef = this.contentComponentVC?.createComponent<C>(
      this.mainComponent
    );

    if (this.isFormComponent) {
      this.contentComponentRef?.setInput('initRecord', this.data?.record);
      this.contentComponentRef?.setInput('cancelText', this.data?.cancelText);
      this.contentComponentRef?.setInput('confirmText', this.data?.confirmText);
      this.contentComponentRef.instance['formSubmission'].subscribe(
        (record: T) => this.onSubmit(record)
      );
      this.contentComponentRef.instance['formCancel'].subscribe(() =>
        this.onClose()
      );
    }

    if (Utility.IsNullOrUndefined(this.mainComponentData)) return;

    Object.entries(this.mainComponentData).forEach(([key, value]) => {
      this.contentComponentRef.instance[key] = value;
    });
  }

  ngOnDestroy(): void {
    if (this.contentComponentRef) {
      this.contentComponentRef.destroy();
    }
  }

  onSubmit(record: T) {
    this.contentComponentRef.instance['lockForm']();
    if (this.closeOnSubmit) this.dialogRef.close(record);
    else this.record$.next(record);
  }

  onClose() {
    this.dialogRef.close();
  }

  unlockForm(): void {
    if (this.isFormComponent) {
      this.contentComponentRef.instance['unlockForm']();
    }
  }
}