<ng-container *ngIf="isDraggable" ; then draggableToolbar; else standardToolbar></ng-container>

<ng-template #draggableToolbar>
  <mat-toolbar color="dark" class="sticky-mat-toolbar" cdkDrag cdkRootElement=".cdk-overlay-pane" cdkDragHandle
    cdkDragBoundary=".cdk-overlay-container" appStackedStickyHeader>
    <ng-container *ngTemplateOutlet="toolbarComponents"></ng-container>
  </mat-toolbar>
</ng-template>

<ng-template #standardToolbar>
  <mat-toolbar color="dark" class="sticky-mat-toolbar" appStackedStickyHeader>
    <ng-container *ngTemplateOutlet="toolbarComponents"></ng-container>
  </mat-toolbar>
</ng-template>

<ng-template #toolbarComponents>
  <h1>{{ heading }}</h1>
  <mat-form-field *ngIf="showFilter">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" (focus)="$event.target.select()" #input />
    <mat-icon #matSuffix>filter_list</mat-icon>
  </mat-form-field>>

  <button type="button" mat-raised-button color="primary" (click)="onRecordAction($event, actions?.create)"
    *ngIf="availableActions?.create">
    {{ newButtonText }}
  </button>

  <button type="button" aria-label="Close Record List Table" mat-icon-button color="default" (click)="onClose()"
    *ngIf="showCloseControl">
    <mat-icon>close</mat-icon>
  </button>
</ng-template>

<!-- Table Content -->
<div class="table-content">
  <ng-content></ng-content>

  <div class="table-scroll" [class.mat-elevation-z]="isElevated">
    <table mat-table matSort matSortActive="{{defaultSort}}" matSortDirection="asc" [dataSource]="dataSource"
      [trackBy]="trackBy">
      <!-- Action Column Definition -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th class="header-cell-actions" mat-header-cell *matHeaderCellDef>
          <div class="cdk-visually-hidden">Actions</div>
        </th>
        <td class="action-cell" mat-cell *matCellDef="let record; let i = index">
          <div class="actions hide flex-md">
            <button type="button" mat-stroked-button color="secondary"
              (click)="onRecordAction($event, actions?.Delete, record, i)"
              *ngIf="availableActions?.delete && !record.removeOnSave">
              Delete
            </button>

            <button type="button" mat-stroked-button color="primary"
              (click)="onRecordAction($event, actions?.View, record, i)" *ngIf="availableActions?.view">
              View
            </button>

            <button type="button" mat-stroked-button color="primary" (click)="onRecordAction($event, actions?.Edit)"
              *ngIf="availableActions?.edit">
              Edit
            </button>
          </div>
        </td>
      </ng-container>

      <!-- Record Property Definition -->
      <ng-container [matColumnDef]="column" *ngFor="let column of propertyCodeNames; let i = index; trackBy: trackBy">
        <th mat-header-cell [style.width]="propertyWidths[i]" *matHeaderCellDef mat-sort-header>
          <div class="property-cell">
            {{propertyDisplayNames[i]}}
          </div>
        </th>
        <td mat-cell *matCellDef="let record">
          <div class="property-cell">
            {{record[column]}}
          </div>
        </td>
      </ng-container>

      <!-- Table Header -->
      <tr mat-header-row *matHeaderRowDef="columns"></tr>

      <!-- Table Row Data -->
      <tr mat-row *matRowDef="let row; columns: columns" (click)="onRecordAction($event, actions?.SelectRow, row)"></tr>

      <!-- Table Row when DataSource is empty -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="columns?.length">
          <ng-container *ngIf="isLoading; then loadingDataMessage; else noDataMessage"></ng-container>
        </td>

        <!-- No Records Message -->
        <ng-template #noDataMessage>
          <div *ngIf="noRecordsMessage">{{ noRecordsMessage }}</div>
          <div *ngIf="!noRecordsMessage">
            No data matching the filter <strong>{{ input?.value }}</strong>
          </div>
        </ng-template>
      </tr>
    </table>
  </div>
  <mat-paginator *ngIf="showPaginator" [pagSizeOptions]="pageSizeOptions"></mat-paginator>
</div>