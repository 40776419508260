import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent implements OnInit {
  @Input() name: string;
  @Input() itemExists: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.data.name = this.data.name || this.name;
    if (this.data?.itemExists === undefined || this.data?.itemExists === null)
      this.data.itemExists = this.itemExists;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
