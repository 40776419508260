<form [formGroup]="formGroup">
  <div class="item-row small-form-field-fill">
    <mat-form-field class="flex-wide">
      <mat-label>{{label}}</mat-label>
      <input matInput formControlName="product" [matAutocomplete]="autoProduct" (focus)="focusField($event.target)"
        (blur)="clearInvalidProduct()" />
      <mat-autocomplete class="compact" #autoProduct="matAutocomplete" [displayWith]="displayProductVal"
        [panelWidth]="'auto'" (closed)="onProductClosed()">
        <mat-option *ngFor="let option of filteredProducts$ | async | slice : 0 : 20" [value]="option">
          {{option.code}} - {{option.description}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="flex-narrow">
      <mat-label>{{ unitOfMeasure || 'Qty' }}</mat-label>
      <input matInput formControlName="quantity" (input)="onInputInteraction($event)" (blur)="onBlurQuantity($event)" />
    </mat-form-field>

    <mat-form-field class="flex-narrow" *ngIf="showSlump">
      <mat-label>Slump</mat-label>
      <input matInput formControlName="slump" (input)="onInputInteraction($event)" />
    </mat-form-field>

    <mat-form-field *ngIf="showSlump" class="flex-wide">
      <mat-label>Use</mat-label>
      <input matInput formControlName="productUse" maxlength="50" required="{{required}}"
        [mat-autocomplete]="autoProductUse" (input)="onInputInteraction($event)" (focus)="$event.target.select()"
        (blur)="trimProductUse($event)" />
      <mat-autocomplete class="compact" #autoProductUse="matAutocomplete" [panelWidth]="'auto'"
        [displayWith]="displayProductUseVal" (closed)="onProductUseClosed($event)"
        (optionSelected)="onInputInteraction($event)">
        <mat-option *ngFor="let option of filteredProductUses | async" [value]="option.name">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</form>