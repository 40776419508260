import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
import { PermissionRoutesEnum } from './models';
import { OrderComponent } from './operations/order/order.component';
import { TicketDashboardComponent } from './operations/ticket-dashboard/ticket-dashboard.component';
import { AccountComponent } from './account/account.component';
import { PlaceOrderComponent } from './operations/place-order/place-order.component';
import { OrderDashboardComponent } from './operations/order-dashboard/order-dashboard.component';

const guards = environment.enableAuth ? [] : [];
export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'operations',
    children: [
      {
        path: 'order-dashboard',
        component: OrderDashboardComponent,
        data: { claims: [PermissionRoutesEnum.Operations_OrderDashboard] },
      },
      {
        path: 'ticket-dashboard',
        component: TicketDashboardComponent,
        data: { claims: [PermissionRoutesEnum.Operations_TicketDashboard] },
      },
      {
        path: 'place-order',
        component: PlaceOrderComponent,
        data: { claims: [PermissionRoutesEnum.Operations_PlaceOrder] },
      },
    ],
  },
  {
    path: 'account',
    component: AccountComponent,
    data: { claims: [PermissionRoutesEnum.Account] },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
