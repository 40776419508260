import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Utility } from '../constants';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent<I, O = I> extends BaseComponent {
  private _readonly: boolean = false;
  get readonly(): boolean {
    return this._readonly;
  }

  @Input() set readonly(value: boolean) {
    this._readonly = value;
    if (value) this.lockForm();
    else this.unlockForm();
  }
  @Input() initRecord: I = null;
  @Input() confirmText: string;
  @Input() cancelText: string;
  @Input() isLocked: boolean = false;

  @Output() formSubmission: EventEmitter<O> = new EventEmitter<O>();
  @Output() formCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeDetection: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  formGroup: UntypedFormGroup;
  public changeDetected: boolean = false;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected titleService: Title
  ) {
    super(titleService);
  }

  protected onFormSubmit() {}
  protected onFormCancel() {}
  protected onInputInteraction(event: any) {}
  protected initForm() {}
  protected checkForErrors() {}

  ngAfterContentInit(): void {
    if (this.isLocked) this.lockForm();
  }

  public lockForm() {
    this.isLocked = true;
    this.formGroup?.disable();
  }

  public unlockForm() {
    this.isLocked = false;
    this.formGroup?.enable();
  }

  protected getInitValue(propertyName: string, defaultValue: any = null): any {
    return FormComponent.getInitValue<I>(
      this.initRecord,
      propertyName,
      defaultValue
    );
  }

  static getInitValue<T>(
    initRecord: T,
    propertyName: string,
    defaultValue: any = null
  ): any {
    return Utility.IsNullOrUndefined(initRecord) ||
      Utility.IsNullOrUndefined(initRecord[propertyName])
      ? defaultValue
      : initRecord[propertyName];
  }

  protected filterAutoCompleteOptions = (
    filterVal: string,
    options: any[],
    qualifiedProperties: string[],
    defaultSortProperty: string,
    showAllByDefault: boolean = false,
    currentValue: any = null,
    currentValueQualifier: (option) => void = function () {}
  ): any[] => {
    return Utility.filterAutoCompleteOptions(
      filterVal,
      options,
      qualifiedProperties,
      defaultSortProperty,
      showAllByDefault,
      currentValue,
      currentValueQualifier
    );
  };

  protected sortAutoCompleteOptions = (
    filterVal: string,
    options: any[],
    qualifiedProperties: string[],
    defaultSortProperty: string
  ): any[] => {
    return Utility.sortAutoCompleteOptions(filterVal, options, qualifiedProperties, defaultSortProperty);
  };

  focusField(inputEl: HTMLInputElement) {
    //highlight input text
    inputEl.select();
  }
}
