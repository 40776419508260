<form class="content" [formGroup]="formGroup">
  <div class="heading-container">
    <mat-icon>person</mat-icon>
    <h2>Personal Information</h2>
  </div>
  <div class="information-row">
    <div class="admin-container" *ngIf="writeAccess || isAdmin">
      <button type="button" mat-flat-button (click)="manageRolesClick()">
        Manage Roles
      </button>
      <button type="button" mat-flat-button (click)="manageUsersClick()">
        Manage Users
      </button>
    </div>
    <div class="information-container">
      <mat-form-field class="flex-wide">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" />
      </mat-form-field>
      <mat-form-field class="flex-wide">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" />
      </mat-form-field>
    </div>
    <div class="information-container">
      <mat-form-field class="flex-wide">
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" />
      </mat-form-field>
      <mat-form-field class="flex-wide">
        <mat-label>Company</mat-label>
        <input matInput formControlName="company" />
      </mat-form-field>
    </div>
  </div>
  <div class="role-container">
    <mat-form-field class="roles">
      <mat-label>Roles</mat-label>
      <textarea matInput formControlName="roles" matAutoSizeMinRows="10"></textarea>
    </mat-form-field>
  </div>
</form>