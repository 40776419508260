<h1 class="mat-dialog-title">Confirm Action</h1>
<div>
  <p *ngIf="data?.itemExists">
    Are you sure you'd like to <strong>delete {{ data?.name }}</strong>?
  </p>
  <p *ngIf="!data?.itemExists">Are you sure you'd like to close the form?</p>
</div>
<div mat-dialog-actions class="flex-end">
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial type="button">
    Cancel
  </button>
  <button mat-flat-button color="primary">
    Confirm <div *ngIf="data?.itemExists">Delete</div>
  </button>
</div>