<div>
  <mat-toolbar class="sticky-mat-toolbar">
    <div class=" dashboard-toolbar small-form-field">
      <div id="heading ">
        <h1>{{heading}}</h1>
      </div>
      <div class="totals-container">
        <div class="dashboard-total">
          <span class="totals-label">Scheduled:</span>
          <mat-icon class="totals-icon" [inline]="true">schedule</mat-icon>
          {{ scheduledTotal | number: '0.0-2'}}
        </div>
        <!-- Per Chris, leave out for now but do not take out functionality -->
        <!-- <div class="dashboard-total">
          <span class="totals-label">Ticketed:</span>
          <mat-icon class="totals-icon" [inline]="true">insert_drive_file</mat-icon>
          {{ ticketedTotal | number: '0.0-2' }}
        </div> -->
        <div class="dashboard-total">
          <span class="totals-label">Delivered:</span>
          <mat-icon class="totals-icon" [inline]="true">local_shipping</mat-icon>
          {{ deliveredTotal | number: '0.0-2' }}
        </div>
      </div>
      <div class="input-container">
        <div class="input-content">
          <mat-form-field class="flex-narrow md-hide no-subscript-wrapper">
            <mat-label>Search</mat-label>
            <input matInput (focus)="$event.target.select()" (keyup)="onSearch($event)" #input />
            <mat-icon matSuffix>filter_list</mat-icon>
          </mat-form-field>
          <mat-form-field class="date-input">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="pickerForDay" [formControl]="selectedDateCtrl"
              (dateChange)="onDateChange($event)" (focus)="$event.target.select()" />
            <mat-datepicker-toggle matIconSuffix [for]="pickerForDay" [disableRipple]="true"></mat-datepicker-toggle>
            <mat-datepicker #pickerForDay [touchUi]="true"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="expand-button">
          <div class="md-hide">
            <button type="button" mat-raised-button class="toggle-all-button" aria-label="Toggle or collapse orders"
              (click)="onToggleAllOrders()" [disabled]="disableExpandAll">
              <p>{{ expandAll ? 'Collapse All' : 'Expand All' }}</p>
            </button>
          </div>
          <div class="lg-hide">
            <button type="button" mat-icon-button color="default" (click)="onToggleAllOrders()"
              [disabled]="disableExpandAll">
              <mat-icon *ngIf="expandAll">unfold_less</mat-icon>
              <mat-icon *ngIf="!expandAll">unfold_more</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>
  <div>
    <div class="table-scroll">
      <table mat-table fixedLayout="true" matSort matSortActive="startTimeFormatted" matSortDirection="asc"
        [dataSource]="dataSource" multiTemplateDataRows [trackBy]="trackBy">
        <!-- Action Column Definition -->
        <ng-container matColumnDef="actions" sticky>
          <th mat-header-cell class="th-order-button" *matHeaderCellDef>
            <span class="cdk-visually-hidden">Actions</span>
          </th>
          <td mat-cell *matCellDef="let record">
            <div class="order-button-wrappper">
              <button mat-flat-button class="order-button" (click)="onViewOrder(record?.order)">
                {{ record?.order.number }}
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Record Property Column Definition -->
        <ng-container [matColumnDef]="column" *ngFor="let column of propertyCodeNames; let i = index; trackBy: trackBy">
          <th mat-header-cell [style.width]="propertyWidths[i]" *matHeaderCellDef mat-sort-header
            class="th-property-header">
            <div class="property-cell">
              {{propertyDisplayNames[i]}}
            </div>
          </th>
          <td mat-cell *matCellDef="let record">
            @if (propertyWidths[i] !== 'auto') {
            <div [style.minWidth]="propertyWidths[i]">
              <ng-container *ngTemplateOutlet="propertyCell"></ng-container>
            </div>
            } @else {
            <div class="cell-width">
              <ng-container *ngTemplateOutlet="propertyCell"></ng-container>
            </div>
            }
            <ng-template #propertyCell>
              <div class="property-cell">
                {{record?.order[column]}}
                <!-- If column is spacing or travel time, append the first two characters after the data value -->
                @if (propertyDisplayNames[i] === 'Spacing' || propertyDisplayNames[i] === 'Travel') {
                {{propertyDisplayNames[i].substr(0, 2).toLowerCase()}} }
              </div>
            </ng-template>
          </td>
        </ng-container>

        <!-- Expanded Content Column Definition -->
        <!-- The detail row is made up of this one column that spans across all columns -->
        <!-- <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let record" [attr.colspan]="columns?.length">
            <div class="record-detail" [style.display]="record?.isExpanded ? 'blocK' : 'none'"
              *ngIf="record?.tickets?.length">
              <ng-container *ngFor="let ticket of record?.tickets; trackBy: trackByTickets">
                <mat-chip [style]="ticket.dashboardStyle" [color]="ticket.ticketChipColor"
                  [matTooltip]="ticket.id + '\n' + ticket.equipment + '\n' + '\n' + ticket.deliveryStatus"
                  (click)="onViewTicket(ticket.ticketId)">
                  {{ticket.status}}
                </mat-chip>
              </ng-container>
            </div>
          </td>
        </ng-container> -->

        <!-- Table Header -->
        <tr mat-header-row *matHeaderRowDef="columns"></tr>

        <!-- Standard Row -->
        <tr tabindex="0" mat-row *matRowDef="let record; columns: columns" (click)="onToggleOrder(record)"
          class="record-row background-{{record?.order.orderStateColor}}"></tr>

        <!-- Expanded Row -->
        <!-- <tr mat-row *matRowDef="let record; columns: ['expandedDetail']" class="detail-row">
        </tr> -->

        <!-- Table Row when DataSource is empty -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="columns?.length">
            <ng-container *ngIf="isLoading; then loadingDataMessage; else noDataMessage"></ng-container>
          </td>

          <ng-template #loadingDataMessage>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </ng-template>

          <!-- No Records Message -->
          <ng-template #noDataMessage>
            <div class="data-message">
              No data matching the filter on <strong>{{formattedSelectedDate}}</strong>
            </div>
          </ng-template>
        </tr>
      </table>
    </div>
  </div>
</div>