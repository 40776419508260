import { Component, ElementRef, ViewChild } from '@angular/core';
import { RecordListComponent } from '../../record-list/record-list.component';
import {
  DateFormat,
  OrderDashboardOrder,
  OrderDashboardOrderData,
  OrderDashboardRecord,
  OrderDashboardTicket,
  PropertyName,
  TicketDashboardData,
  UserData,
  ZendiTicketStateData,
} from '../../models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { MessageService } from '../../message.service';
import { OperationService } from '../../entity-data-service/operation.service';
import { AuthorizationService } from '../../authorization/authorization.service';
import { DateTime, Duration } from 'luxon';
import {
  BehaviorSubject,
  combineLatest,
  interval,
  skipWhile,
  Subscription,
  takeUntil,
} from 'rxjs';
import { ShortNumericColumnWidth, Utility } from '../../constants';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-order-dashboard',
  templateUrl: './order-dashboard.component.html',
  styleUrls: ['./order-dashboard.component.scss'],
})
export class OrderDashboardComponent extends RecordListComponent<OrderDashboardRecord> {
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  heading: string;
  isLoading: boolean;
  isHandset: boolean = false;
  expandAll: boolean = false;
  disableExpandAll: boolean = false;
  dashboardDataLoaded: boolean = false;
  ticketStatesLoaded: boolean = false;
  refreshInterval: number = 30;
  user: UserData;
  selectedDate: DateTime;
  formattedSelectedDate: string = '';
  intervalSubscription: Subscription;
  scheduledTotal: number = 0;
  ticketedTotal: number = 0;
  deliveredTotal: number = 0;
  orders: OrderDashboardOrderData[] = [];
  allTickets: TicketDashboardData[] = [];
  tickets: OrderDashboardTicket[] = [];
  ticketStates: ZendiTicketStateData[] = [];
  allowedTicketStates: ZendiTicketStateData[] = [];
  selectedDateCtrl: UntypedFormControl = new UntypedFormControl();
  records$: BehaviorSubject<OrderDashboardRecord[]> = new BehaviorSubject<
    OrderDashboardRecord[]
  >([]);

  recordProperties: PropertyName[] = [
    new PropertyName('loadTimeFormatted', 'Load', ShortNumericColumnWidth),
    new PropertyName('travelTime', 'Travel', ShortNumericColumnWidth),
    new PropertyName('startTimeFormatted', 'Start', ShortNumericColumnWidth),
    new PropertyName('spacing', 'Spacing', ShortNumericColumnWidth),
    new PropertyName('fromLocationCode', 'Plant', ShortNumericColumnWidth),
    new PropertyName('primaryProduct', 'Primary Product'),
    new PropertyName('deliveryStatus', 'Delivery Status'),
    new PropertyName('summary', 'Summary Status'),
    new PropertyName('orderStateName', 'Status'),
  ];

  trackBy = (index: number, record: OrderDashboardRecord) => {
    return record.id;
  };

  trackByTickets = (record: OrderDashboardTicket) => {
    return record.id;
  };

  constructor(
    protected titleService: Title,
    protected messageService: MessageService,
    protected operationService: OperationService,
    protected authService: AuthorizationService
  ) {
    super(titleService, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.user = this.authService.verifiedAccountData?.mccUser;
    this.heading = `${this.user?.customerName} Orders`;

    this.selectedDate = DateTime.now();
    this.formattedSelectedDate = this.selectedDate.toFormat(
      DateFormat.DateShort
    );
    this.selectedDateCtrl = new UntypedFormControl(new Date());
    this.subscribeToSubjects();
    this.subscribeToServices();
    this.loadData(DateTime.now());

    this.setRefreshSubscription();
  }

  protected subscribeToSubjects() {
    this.records$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((records) => {
      this.dataSource.data = records;
      this.disableExpandAll = !this.dataSource?.data?.some(
        (d) => d.tickets.length
      );
      this.isLoading = false;
    });
  }

  protected subscribeToServices() {
    this.operationService.zendiTicketStates
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data)
      )
      .subscribe((records) => {
        this.ticketStates = records;
        this.ticketStatesLoaded = true;
        this.setDashboard();
      });

    combineLatest([
      this.operationService.dashboardOrders,
      this.operationService.dashboardTickets,
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data.every((e) => e))
      )
      .subscribe(([orders, tickets]) => {
        this.allTickets = tickets?.filter((t) => Utility.HasValue(t.orderId));
        this.orders = orders;
        this.dashboardDataLoaded = true;
        this.setDashboard();
      });

    this.operationService.deliveredTotal
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data)
      )
      .subscribe((record) => {
        this.deliveredTotal = record['value']?.deliveredTotal;
      });

    this.operationService.ticketedTotal
      .pipe(
        takeUntil(this.ngUnsubscribe),
        skipWhile((data) => !data)
      )
      .subscribe(
        (record) => (this.ticketedTotal = record['value']?.ticketedTotal)
      );
  }

  /**
   * Refresh immediate then every x seconds
   */
  setRefreshSubscription() {
    this.intervalSubscription = interval(this.refreshInterval * 1000)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.loadData(this.selectedDate);
      });
  }

  protected loadData(selectedDate: DateTime = DateTime.now()): void {
    super.loadData();
    if (this.selectedDate.startOf('day') !== selectedDate.startOf('day')) {
      this.resetPaginator();
    }

    this.isLoading = true;
    const customerId = this.user.customerId;
    this.operationService.getTicketDashboardTickets(customerId, selectedDate);
    this.operationService.getOrderDashboardOrders(customerId, selectedDate);
    if (!this.ticketStates.length) this.operationService.getZendiTicketStates();
    this.operationService.getDeliveredTotal(customerId, selectedDate);
    // Dev note: leave for when they want ticketed total displayed
    // this.operationService.getTicketedTotal(customerId, selectedDate);
  }

  onSearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.resetPaginator();
  }

  onDateChange(datepicker) {
    if (datepicker.value !== null) {
      this.selectedDate = DateTime.fromJSDate(datepicker.value);
      this.formattedSelectedDate = this.selectedDate.toFormat(
        DateFormat.DateShort
      );
      this.loadData(this.selectedDate);
    }
  }

  /**
   * Expand the order row to display Tickets
   */
  onToggleOrder(record: OrderDashboardRecord) {
    if (!record.tickets?.length) return;

    record.isExpanded = !record.isExpanded;
    this.expandAll = record.isExpanded;
    if (!this.dataSource?.data?.some((d) => d.tickets.length && d.isExpanded)) {
      this.expandAll = false;
    }
  }

  /**
   * Expand all Order rows to display Tickets
   */
  onToggleAllOrders() {
    this.expandAll = !this.expandAll;
    this.dataSource?.data?.forEach((r) => (r.isExpanded = this.expandAll));
  }

  calculateScheduledTotal() {
    const scheduledOrders = this.orders.filter((o) => !o.isCancelledState);
    this.scheduledTotal = scheduledOrders.reduce((subTotal, o) => {
      subTotal += o.quantity;
      // round subtotal to 2 decimal places
      return Math.round(subTotal * 100) / 100;
    }, 0);
  }

  getAllowedTicketStates() {
    //group Ticket States by Ticket Workflow
    const grpTicketStates = this.ticketStates?.reduce((result, ticketState) => {
      const workflowId = ticketState.ticketWorkflowId;
      if (!result[workflowId]) {
        result[workflowId] = [];
      }

      result[workflowId].push(ticketState);
      return result;
    }, {} as { [workflowId: number]: ZendiTicketStateData[] });

    let dashboardTicketStates = [];
    // filter tickets so that only tickets with an Order, are in a delivered or completed state or greater are displayed
    for (const key in grpTicketStates) {
      // sort each grouped ticket states in ascending order by sequence
      const ticketStates = grpTicketStates[key]?.sort(
        (a, b) => a.sequence - b.sequence
      );

      const deliveredState = ticketStates.find((ts) => ts.isDeliveredState);
      const completedState = ticketStates.find((ts) => ts.isCompletedState);
      let allowedTicketStates: ZendiTicketStateData[] = [];

      if (Utility.HasValue(deliveredState)) {
        // All states between Delivered & Completed should be displayed
        allowedTicketStates = ticketStates.filter(
          (ts) =>
            ts.sequence >= deliveredState.sequence &&
            ts.sequence < completedState.sequence
        );

        // Any state at Completed or after will display as completed
        allowedTicketStates.push(completedState);
      } else if (Utility.HasValue(completedState)) {
        // Any state at Completed or after will display as completed
        allowedTicketStates.push(completedState);
      }
      dashboardTicketStates = dashboardTicketStates.concat(allowedTicketStates);

      this.allowedTicketStates = dashboardTicketStates.sort((a, b) => {
        return (
          a.ticketWorkflowId - b.ticketWorkflowId || a.sequence - b.sequence
        );
      });
    }
  }

  calculateDurationTime(
    ticket: OrderDashboardTicket,
    order: OrderDashboardOrderData
  ) {
    const state = ticket.ticketState;
    if (state.durationCalculation) {
      let durationThreshold = state.durationThreshold
        ? state.durationThreshold
        : 0;

      const ticketStateDateTime = DateTime.fromJSDate(
        new Date(ticket.ticketStateDateTime)
      );
      switch (state.durationCalculation) {
        case 1:
          // printed, queued (1, 5, 6, 7)
          return DateTime.fromJSDate(new Date(ticket.loadStartDateTime)).plus(
            durationThreshold
          ).minute;
        case 2:
          // loading (6), ticketed, to job (4)
          return ticketStateDateTime.plus(durationThreshold).minute;
        case 3:
          // to job (1,5,6,7) , ready/return (1,4,7), repeat/return (1,4,7), loaded/return (1,4,7), returning (5)
          return ticketStateDateTime
            .plus(order.travelTime)
            .plus(durationThreshold).minute;
        case 4:
          // at job (back haul) (7,1), begin pour, at job (6,4)
          return ticketStateDateTime.plus(order.spacing).plus(durationThreshold)
            .minute;
        case 5:
          // returning (6), loading (5)
          return ticketStateDateTime
            .plus(order.loadTime)
            .plus(durationThreshold).minute;
        case 6:
          // at job (1,7)
          return ticketStateDateTime
            .plus(order.estimatedTimeAtJob)
            .plus(durationThreshold).minute;
        case 7:
          // if return time has no value, default to travel time (case 3)
          return ticketStateDateTime
            .plus(
              Utility.HasValue(ticket.returnTime)
                ? ticket.returnTime
                : order.travelTime
            )
            .plus(durationThreshold).minute;
        default:
          return null;
      }
    }
    return null;
  }

  calculateStateMins(
    ticket: OrderDashboardTicket,
    order: OrderDashboardOrderData
  ) {
    const state = ticket.ticketState;
    if (Utility.IsNullOrUndefined(state)) return null;

    const date = new Date(ticket.ticketStateDateTime);
    const dateTime = DateTime.fromJSDate(date);
    let then = null;
    let isDefaultCalculation = false;
    let isLoadTimeCalculation = false;

    if (
      Utility.HasValue(state.durationThreshold) &&
      !state?.durationCalculation
    ) {
      then = Duration.fromObject(dateTime).as('minutes');
    } else if (Utility.HasValue(state.durationCalculation)) {
      then = this.calculateDurationTime(ticket, order);
      isLoadTimeCalculation = then && state?.durationCalculation === 1;
    } else {
      then = dateTime;
      isDefaultCalculation = true;
    }
  }

  filterTickets() {
    if (!this.allowedTicketStates.length) {
      this.getAllowedTicketStates();
    }

    // only the allowed Ticket State in a delivered or completed state or greater are displayed
    this.tickets = [];
    this.allTickets?.forEach((t) => {
      const ticketStates = this.allowedTicketStates.filter(
        (ts) => ts.ticketWorkflowId === t.ticketWorkflowId
      );

      const completedState = ticketStates.find((ts) => ts.isCompletedState);

      if (t.ticketStateSequence >= ticketStates[0]?.sequence) {
        // Set the Ticket State Id Completed Ticket State Id because any state at or after Completed will display as Completed
        if (t.ticketStateSequence > completedState?.sequence) {
          t.ticketStateId = completedState.id;
        }

        const ticket = new OrderDashboardTicket(t);
        ticket.ticketState = ticketStates.find(
          (ts) => ts.id === t.ticketStateId
        );

        ticket.dashboardStyle = {
          // marginLeft: `calc(100% / ${
          //   (ticket.ticketState?.dashboardColumns)
          // } * ${ticket.ticketState.dashboardColumnIndex - 1} + 4px)`,
        };

        //TODO: state duration calculation
        // const order = this.orders.find((o) => o.id === ticket.orderId);
        // this.calculateStateMins(ticket, order);

        this.tickets.push(ticket);
      }
    });
  }

  setDashboard() {
    if (!this.dashboardDataLoaded || !this.ticketStatesLoaded) return;

    this.filterTickets();

    // map Order only after ticket data has been filtered
    const orderDashboardRecords = this.orders.map((o) => {
      const order = new OrderDashboardOrder(o);

      // find/create the record (retains expanded state)
      const record = this.records$.value?.find((r) => r.id === o.id) || {
        id: order.id,
        // new record auto-expands if all other existing records are expanded
        isExpanded: this.expandAll,
        order: order,
      };
      // update the record's order
      record.order = order;
      // update the record's tickets
      record.tickets = this.tickets
        .filter((t) => t.orderId === order.id)
        .sort((a, b) => a.ticketStateSequence - b.ticketStateSequence);

      return record;
    });

    this.records$.next(orderDashboardRecords);
    this.calculateScheduledTotal();
  }

  protected defineExtraColumns() {
    this.columns = ['actions', ...this.columns];
  }

  onViewOrder(record: OrderDashboardOrder) {
    console.log('open order form', { record });
  }

  onViewTicket(ticketId: number) {
    console.log('open ticket form', { ticketId });
  }
}
